import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  main: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 100,
    top: 0,
    left: 0,
    background: "#FD0049",
  },
  content: {
    minWidth: 400,
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    background: "#FFF",
    padding: 30,
    borderRadius: 30,
  },
  subContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: "100%",
    paddingBottom: 20,
  },
  textRedirect: {
    paddingBottom: 20,
    fontWeight: 500,
  },
  contentAltError: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    background: "#FFF",
    padding: 30,
    borderRadius: 30,
    minWidth: 500,
    minHeight: 300,
  },
  contentReasonError: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 30,
  }
}))
