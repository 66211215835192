import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(() => ({
  itemTree: {
    backgroundColor: "transparent",
    "&:hover > .MuiTreeItem-content": {
      backgroundColor: "transparent",
    },

    "&.Mui-selected > .MuiTreeItem-content:hover": {
      backgroundColor: "transparent",
    },
    "& > .MuiTreeItem-content .MuiTreeItem-label:hover": {
      backgroundColor: "transparent",
    },

    "&:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent",
    },

    "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover  , &.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "transparent",
      },
  },
}))
