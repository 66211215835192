const sandbox = {
  // Only Order Status
  PENDING_PREPARATION: {
    HD: {
      order: "Preparación pendiente",
    },
    SP: {
      order: "Preparación pendiente",
    },
    RT: {
      order: "Preparación pendiente",
    }
  },
  // Orders and Sgs Status together
  IN_TRANSIT: {
    HD: {
      order: "En ruta",
      sg: "En ruta"
    },
    SP: {
      order: "En ruta",
      sg: "En ruta"
    },
    TR: {
      order: "En ruta",
      sg: "En ruta"
    }
  },
  CLOSED: {
    SP: {
      order: "Retirado por cliente",
      sg: "Retirado por cliente"
    }
  },
  // Only Sgs Status
  AWAITING_STORE_CONFIRMATION: {
    HD: {
      sg: "Preparacion pendiente",
    },
    SP: {
      sg: "Preparacion pendiente",
    },
    RT: {
      sg: "Preparacion pendiente",
    },
    TR: {
      sg: "Preparacion pendiente",
    }
  },
  PENDING_PACKAGE: {
    HD: {
      sg: "En preparación"
    },
    SP: {
      sg: "En preparación"
    },
    RT: {
      sg: "En preparación"
    },
    TR: {
      sg: "En preparación"
    }
  },
  READY_FOR_PICKUP: {
    SP: {
      order: "Listo para retiro",
      sg: "Listo para retiro"
    },
  },
}

export default sandbox;