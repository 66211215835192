import i18n from "i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import global_es from "./es/index.json"
import global_en from "./en/index.json"
import global_pt from "./pt/index.json"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    // debug: true,
		whitelist: ["es", "en", "pt"],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    resources: {
      es: {
        global: global_es,
      },
      en: {
        global: global_en,
      },
      pt: {
        global: global_pt,
      },
    },
  })

export default i18n
