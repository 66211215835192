import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))
