import { axiosClientOLM } from "../config/axios"
import tokenAuth from "../config/token"

async function updateCourier(token, json) {
  tokenAuth(token, "OLM")
  let result = await axiosClientOLM.put(
    process.env.REACT_APP_API_URL_COURIERS,
    json,
    {
      headers: { "Content-Type": "application/json" },
    }
  )
  return result
}

async function getCourierIdEdit(token, id) {
  tokenAuth(token, "OLM")
  const url = process.env.REACT_APP_API_URL_COURIERS + `/${id}`
  let result = await axiosClientOLM.get(url, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

async function getCourier(token, json) {
  tokenAuth(token, "OLM")
  const url = process.env.REACT_APP_API_URL_COURIERS + `/all`
  let result = await axiosClientOLM.post(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}
async function getCourierId(token, json) {
  tokenAuth(token, "OLM")
  let result = await axiosClientOLM.post(
    process.env.REACT_APP_API_URL_COURIERS,
    json,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    }
  )
  return result
}

export { getCourier, getCourierId, getCourierIdEdit, updateCourier }
