import React, {useContext} from 'react';
import { useHistory } from "react-router-dom"
import {useTranslation} from 'react-i18next'
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew"

import {logout} from "@Context/actions/AuthActions"
import { AuthContext } from "@Context/context/AuthContext"

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: theme.spacing(4),
  },
  reset: {
    paddingLeft: theme.spacing(2),
  },
}))

const ButtonSidebarNav = (props) => {
  const {route, name, icon: Icon} = props
  const history = useHistory()
  const {t} = useTranslation('global')
  const classes = useStyles()

  const activeColor = { color: "#FD0049", fontWeight: 600 }

  const activeRouteHelper = (route, activeRoute) => {
    return route === activeRoute ? activeColor : {}
  }

  return (
    <ListItem
      className={clsx(classes.button, {
        [classes.reset]: Icon,
      })}
      button onClick={() => history.push(route)}
    >
      {Icon && <ListItemIcon>
        <Icon />
      </ListItemIcon>}
      <ListItemText
        primary={t(name)}
        style={activeRouteHelper(route, history.location.pathname)}
      />
    </ListItem>
  );
}

export const SignOutButton = () => {
  const { setUser } = useContext(AuthContext)
  const {t} = useTranslation('global')
  const classes = useStyles()

  return (
    <ListItem
      className={classes.reset}
      button onClick={() => setUser(logout())}
    >
      <ListItemIcon>
        <PowerSettingsNewIcon />
      </ListItemIcon>
      <ListItemText primary={t("sing-off")} />
    </ListItem>
  )
}
 
export default ButtonSidebarNav;