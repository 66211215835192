import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormLocations").render()

const EditLocationScreen = () => {
  const { t } = useTranslation("global")
  const { handleRoutingBreadcrumbs, editLocation } = useContext(GlobalContext)
  const { permission, checking } = useUserPermissions({
    resources: [rsc.LoCrudEditLocation],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (!permission[rsc.LoCrudEditLocation]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-location"),
      routes: [
        { name: t("logistics"), route: "/locations" },
        { name: t("edit-location"), route: "/locations/edit" },
      ],
      icon: <AllInboxIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component info={editLocation} edit={true} load={false} />
    </Suspense>
  )
}

export default EditLocationScreen
