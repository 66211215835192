import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  styledForm: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    minWidth: 250,
    padding: 10,
  },
}))
