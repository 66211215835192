import { axiosClientDOWNLOAD } from "@Config/axios"
import tokenAuth from "@Config/token"
import axios from "axios"
import axiosRetry from 'axios-retry';

const getCurrentEnv = (env=false) => {
  if (env) {
    console.log(`Reporteria ${env}`)

    return env
  }
  const environments = ["dev", "uat", "prod"]
  const isLocal = window.location.href.includes("localhost")
  const isDev = window.location.href.includes(environments[0])
  const isUat = window.location.href.includes(environments[1])

  if (isDev || isLocal) {
    return environments[0]
  } else if (isUat) {
    return environments[1]
  } else {
    return environments[2]
  }
}

//Replace tokenNot with real token when service use our tokens
async function getTemplate(token, templateId) {
  tokenAuth(token, "DOWNLOAD")
  const url = `/reports/templates/${templateId}`
  let result = await axiosClientDOWNLOAD.get(url, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

async function getReport(token, json, templateId) {
  tokenAuth(token, "DOWNLOAD")
  const url = `/reports/${templateId}`
  let result = await axiosClientDOWNLOAD.post(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}
async function checkDownloadContent(url,cancelToken) {

  //20 Is more than necessary , 10 is like a average number , 1 is for testing usability
  axiosRetry(axios, {
    retries: 10, 
    retryDelay: (retryCount) => {
      return retryCount * 1500; 
    },
    retryCondition: (error) => {
      return error.response.status === 403;
    },
  });
  const response = await axios({
    method: 'GET',
    url: url,
    cancelToken:cancelToken
  })

  return response
}

async function deleteReportFile(token,templateId,fileName) {
  tokenAuth(token, "DOWNLOAD")
  const url = `/reports/${templateId}/files/${fileName}`
  let result = await axiosClientDOWNLOAD.delete(url, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}


export { getTemplate, getReport, getCurrentEnv, checkDownloadContent,deleteReportFile }
