import __t from './i18n/es_ES'

class OmnixCustomData extends HTMLElement {

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
  }

  static get observedAttributes() {
    return ['data-custom'];
  }

  attributeChangedCallback(attr, oldVal, newVal) {
    this[attr] = newVal;
  }

  fillTemplateValues() {
    const values = JSON.parse(this['data-custom'])
    let outputHTML = ''

    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        // if (!values[key]) { continue; }

        if (typeof values[key] === 'object') {        
          for (let innerKey in values[key]) { 
            if (!values[key][innerKey]) { continue; }
            outputHTML += `
              <p><strong>${__t(innerKey)}:</strong> ${__t(values[key][innerKey])}</p> 
            `
          }
        } else {
          outputHTML +=`
            <p><strong>${__t(key)}:</strong> ${__t(values[key])}</p>  
          `
        }

      }
    }
    return outputHTML;
  }

  getTemplate() {
    const template = document.createElement('template')
    const templateValues = this.fillTemplateValues()

    template.innerHTML = `
            <div>
                ${templateValues}
            </div>
            ${this.getStyles()}
        `
    return template
  }

  getStyles() {
    return `
            <style>            
                :host {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                :host-context(.omnix-custom-data--m0) {

                }
                :host-context(.omnix-custom-data--m0) p{
                    margin: 0;
                }
            </style>
        `
  }

  render() {
    this.shadowRoot.appendChild(this.getTemplate().content.cloneNode(true));
  }

  connectedCallback() {
    this.render();
  }

  disconnectedCallback() {

  }

}

customElements.define('omnix-custom-data', OmnixCustomData)