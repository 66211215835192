/**
 * Initial state to use in Reducers - Information about the client's
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 * @returns {function}
 */
const ClientReducerInit = {
  env: "dev",
  website: "adretail",
}

export default ClientReducerInit
