import axiosClient from "@Config/axios"

export async function authenticateUser({ user: key, password: secret }) {
  const url = process.env.REACT_APP_API_URL_USER
  const params = {
    key,
    secret,
    options: { permission: true },
  }
  let result = await axiosClient.post(url, params, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
  })
  return result
}
