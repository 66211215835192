import React, {createContext, useReducer} from 'react';
import AppReducer from './../reducers/AppReducer'

import AllInboxIcon from "@material-ui/icons/AllInbox";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from "@material-ui/core";

const initialState = {
  openDrawer: true,
  errorSession: false,
  errorSessionCode: null,
  messageError: null,
  routingBreadcrumbs: {
    titleHeader: "Articulos",
    routes: [{ name: "Inventario", route: "/items" }],
    icon: <AllInboxIcon style={{marginRight: 5,opacity: 0.5}} />,
  },
  editLocation:null,
  unexpectedErrorAlert: null,
  isLoadingAnyTable: false,
};

export const GlobalContext = createContext(initialState)

export const GlobalProvider = props => {
  const [state, dispatch] = useReducer(AppReducer, initialState)
  const theme = useTheme()
  const breakpoints = {
    xs: useMediaQuery(theme.breakpoints.only('xs')),
    sm: useMediaQuery(theme.breakpoints.only('sm')),
    md: useMediaQuery(theme.breakpoints.only('md')),
    lg: useMediaQuery(theme.breakpoints.only('lg')),
    xl: useMediaQuery(theme.breakpoints.only('xl'))
  }

  const handleDrawer = () => {
    dispatch({
      type: 'HANDLE_DRAWER',
    })
  }

  const handleErrorSession = (error) => {
    dispatch({
      type: 'HANDLE_ERROR_SESSION',
      payload: error
    })
  }

  const handleRoutingBreadcrumbs = (routesBreadcrumbs) => {
    dispatch({
      type: 'HANDLE_ROUTING_BREADCRUMBS',
      payload: routesBreadcrumbs
    })
  }

  //*Temporal fix  Meanwhile Location service be able to search by ID */
  const handleLocation = (info) => {
    dispatch({
      type: 'HANDLE_LOCATION',
      payload: info
    })
  }

  const setUnexpectedErrorAlert = data => {
    dispatch({
      type: 'HANDLE_UNEXPECTED_ERROR',
      payload: data
    })
  }

  const setIsLoadingAnyTable = load => {
    dispatch({
      type: 'HANDLE_IS_LOADING_ANY_TABLE',
      payload: load
    })
  }

  return (
    <GlobalContext.Provider
      value={{
        errorSession: state.errorSession,
        errorSessionCode: state.errorSessionCode,
        messageError: state.messageError,
        openDrawer: state.openDrawer,
        routingBreadcrumbs: state.routingBreadcrumbs,
        editLocation : state.editLocation,
        unexpectedErrorAlert: state.unexpectedErrorAlert,
        isLoadingAnyTable: state.isLoadingAnyTable,
        breakpoints,
        handleDrawer,
        handleErrorSession,
        handleRoutingBreadcrumbs,
        handleLocation,
        setUnexpectedErrorAlert,
        setIsLoadingAnyTable
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider