import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import clsx from "clsx"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import useStyles from "./styles"
import { GlobalContext } from "@Context/context/GlobalState"
import LoginLogo from "@Img/login/LogInLogo.png"

const Header = ({ width }) => {
  const history = useHistory()
  const classes = useStyles({ width })
  const { openDrawer, handleDrawer, routingBreadcrumbs } =
    useContext(GlobalContext)

  return (
    <AppBar
      position="absolute"
      className={clsx(
        classes.appBar,
        openDrawer & (width !== true) && classes.appBarShift
      )}
    >
      <div className={classes.containerHeader}>
        {!openDrawer && (
          <img className={classes.imageBrand} src={LoginLogo} alt="logoOmnix" />
        )}

        <Toolbar className={classes.toolbar}>
          <div className={classes.hamburgerNavContainer}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            <div>
              <Typography
                component="h6"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {routingBreadcrumbs.titleHeader || ""}
              </Typography>
              <div className={classes.hamburgerNavContainer}>
                {routingBreadcrumbs.icon || null}
                <Breadcrumbs aria-label="breadcrumb" color="inherit">
                  {(routingBreadcrumbs.routes || []).map((link, i) => (
                    <Link
                      color="inherit"
                      // href={link.route}
                      onClick={() => history.push(link.route)}
                      key={i}
                      className={
                        i === 0 ? classes.breadcrumbActive : classes.breadcrumb
                      }
                    >
                      {link.name}
                    </Link>
                  ))}
                </Breadcrumbs>
              </div>
            </div>
          </div>
          {/* <div>
            <Hidden smDown>
              <IconButton color="inherit">
                <SearchIcon />
              </IconButton>
              <IconButton color="inherit">
                <PersonIcon />
              </IconButton>
              <IconButton color="inherit">
                <SettingsIcon />
              </IconButton>
            </Hidden>
          </div> */}
        </Toolbar>
      </div>
    </AppBar>
  )
}

export default Header
