import React from "react"
import FormGroup from "@material-ui/core/FormGroup"
import Popover from "@material-ui/core/Popover"
import TextField from "@material-ui/core/TextField"
import useStyles from "@Components/TableMassiveLoads/styles"
import { useTranslation } from "react-i18next"

const PopOverFilters = ({
  openFilters,
  setopenFilters,
  anchorEl,
  who,
  handleValue,
  values,
}) => {
  const classes = useStyles()
  const { t } = useTranslation("global")
  const switchRender = (who) => {
    const indVal = values.findIndex(({ name }) => name === who)
    switch (who) {
      case "Id":
        return (
          <FormGroup className={classes.styledForm}>
            <TextField
              variant="outlined"
              id="Id-Filter"
              label={t("Id")}
              value={values[indVal].value}
              onChange={(event) => handleValue(who, event.target.value)}
            />
          </FormGroup>
        )
      default:
        return <h1>{who}</h1>
    }
  }

  return (
    <Popover
      id="filter-chip"
      open={openFilters}
      anchorEl={anchorEl}
      onClose={() => setopenFilters(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {switchRender(who)}
    </Popover>
  )
}

export default PopOverFilters
