import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  footerDefaultItemsPosition: {
    with: "100%",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    }
  },
  footerDefaultItemsPositionWithMenuButton: {
    with: "100%",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    }
  }
}));
