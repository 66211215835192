import React from "react"
import { useHistory } from "react-router-dom"
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
// import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet"
import useStyles from "./styles"
import SelectLanguage from "@Components/SelectLanguage"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"

const GeneralSettingsBody = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation("global")
  const [incrementalId, setIncrementalId] = React.useState(false)
  const { permission, checking } = useUserPermissions({
    resources: [
      rsc.Setting,
    ],
  })

  React.useEffect(() => {
    if (!checking) {
      setIncrementalId(permission[rsc.Setting])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  const RowLanguage = () => (
    <TableRow>
      <TableCell>
        <Typography variant="body2" className={classes.labels}>
          {t("language")}
        </Typography>
        <SelectLanguage />
      </TableCell>
    </TableRow>
  )

  const RowSequenceConfig = () => (
    <TableRow>
      <TableCell>
        <Typography variant="body2" className={classes.labels}>
          {t("incremental-omnix-id-parameters")}
        </Typography>
        <Button
          variant="contained"
          size="small"
          className={classes.cancelButton}
          endIcon={<SettingsEthernetIcon />}
          onClick={() => history.push("/settings/incremental_omnix_id")}
        >
          {t("go")}
        </Button>
      </TableCell>
    </TableRow>
  )

  // const RowRolesAndProfiles = () => (
  //   <TableRow>
  //     <TableCell>
  //       <Typography variant="body2" className={classes.labels}>
  //         {t("roles-and-profiles")}
  //       </Typography>
  //       <Button
  //         variant="contained"
  //         size="small"
  //         className={classes.cancelButton}
  //         endIcon={<SupervisedUserCircleIcon />}
  //       >
  //         {t("go")}
  //       </Button>
  //     </TableCell>
  //   </TableRow>
  // )

  return (
    <div>
      <TableContainer component={Paper} className={classes.mainContainer}>
        <Table className={classes.table} aria-label="simple table">
          {incrementalId && <RowSequenceConfig />}
          <RowLanguage />
        </Table>
      </TableContainer>
    </div>
  )
}

export default GeneralSettingsBody
