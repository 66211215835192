import React from "react"
import { useHistory } from "react-router-dom"
import IconButton from "@material-ui/core/IconButton"
import PersonIcon from "@material-ui/icons/Person"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import useStyles from "./styles"

const SidebarNavigation = () => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <div className={classes.groupIconsContainer}>
      <IconButton onClick={() => history.goBack()} color="primary">
        <ArrowBackIcon />
      </IconButton>
      <IconButton onClick={() => history.push("/profile")} color="primary">
        <PersonIcon />
      </IconButton>
      <IconButton onClick={() => history.goForward()} color="primary">
        <ArrowForwardIcon />
      </IconButton>
    </div>
  )
}

export default SidebarNavigation
