import { GlobalContext } from "@Context/context/GlobalState"
import React, { useContext, useEffect } from "react"
import SettingsIcon from "@material-ui/icons/Settings"
import GeneralSettingsBody from "@Components/GeneralSettingsBody"
import { useTranslation } from "react-i18next"

const GeneralSettingsScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("settings"),
      routes: [{ name: t("settings"), route: "/settings" }],
      icon: <SettingsIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])
  return <GeneralSettingsBody />
}

export default GeneralSettingsScreen
