import { gettingShippingGroupByOrder } from '@Services/shippingGroupService';
import {useState, useEffect, useContext} from "react";
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"

const useGetSgsByOrderOOM = ({orderId}) => {
  const { user } = useContext(AuthContext)
  const { handleErrorSession } = useContext(GlobalContext)
  const [sgs, setSgs] = useState([])
  const [load, setLoad] = useState(true)

  useEffect(() => {
    orderId && getShippingGroupsAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  const getShippingGroupsAction = async () => {
    try {
      let response = await gettingShippingGroupByOrder(
        user.user.token,
        orderId
      )
      let result = response?.data?.message || []
      setSgs(result)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      if (error.response.status !== 404) {
        handleErrorSession({
          error: true,
          errorCode: error.response.status,
          messageError: error?.response?.data?.message,
        })
      }
    }
  }

  return {
    sgs,
    loadSgs: load
  }
}

export default useGetSgsByOrderOOM