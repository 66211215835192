import React, { useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import { nameComponent } from "@Website/"

function ClientComponent(props) {
  const {
    AdretailDev,
    AdretailUat,
    AdretailProd,
    CasaideasDev,
    CasaideasUat,
    CasaideasProd,
    LogytechDev,
    LogytechUat,
    LogytechProd,
    SandboxDev,
    SandboxUat,
    SandboxProd,
    NaveDev,
    SodimacDev,
    SodimacUat,
    SodimacProd,
    name = nameComponent(),
    debug = false,
  } = props
  const { setUnexpectedErrorAlert } = useContext(GlobalContext)

  const components = {
    AdretailDev,
    AdretailUat,
    AdretailProd,
    CasaideasDev,
    CasaideasUat,
    CasaideasProd,
    LogytechDev,
    LogytechUat,
    LogytechProd,
    SandboxDev,
    SandboxUat,
    SandboxProd,
    NaveDev,
    SodimacDev,
    SodimacUat,
    SodimacProd
  }

  if (debug && components[name]) {
    const debugObject = {
      clientEnv: name,
      componentName: components[name]?.type?.name,
      parentFileSource: components[name]?._source?.fileName,
      extraInfo: components[name],
    }
    console.log("Debug ON", debugObject)
  }

  try {
    return components[name]
      ? React.createElement(components[name].type, components[name].props)
      : null
  } catch (error) {
    setUnexpectedErrorAlert({
      reason: { message: error.message || "Unknow error" },
    })
    return null
  }
}

export default ClientComponent
