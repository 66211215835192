const translates = {
  // Sources
  sourceType: 'Tipo source',
  addressStreet: 'Calle',
  addressRegion: 'Region',
  addressCity: 'Ciudad',
  addressComplement: 'Complemento de direccion',
  
  // Casaideas custom orders
  sapId: 'id_SAP',
  sistema: 'sistema',
  paisId: 'pais_id',
  localId: 'local_id',
  startEstimatedDeliveryHour: 'inicio_hora_entrega_estimada',
  endEstimatedDeliveryHour: 'fin_hora_entrega_estimada',
  modoRegPedido: 'modo_reg_pedido',
  modoRegVenta: 'modo_reg_venta',
  regPedidoOrigen: 'reg_pedido_origen',
  tipoDoc: 'tipo_doc',
  
  // Adretail custom orders
  folio: 'Número de boleta (folio)',
  // tipoCliente: '',
  // tipoDocumento: '',
  // tipoVenta: '',
  // codSucOrigen: '',
  // estadoDoc: '',
  // auMaquina: '',
  // dsctoPrimeraCompra: '',
  // tipoGeneraDoc: '',
  // auUsuario: '',
  // tipoDocOrigen: '',
  
  // Default
  systemId: 'Id Sistema',
  name: 'Nombre',
  phone: 'Teléfono',
  email: 'Correo electronico',

  ///Custom coverage Table Object
  type : "Tipo",
  data : "Información",
  criteria0 : "Criterio 0",
  criteria1 : "Criterio 1",
  criteria2 : "Criterio 2",
  criteria3 : "Criterio 3",
  criteria4 : "Criterio 4",

  ///For CSI table Order vs SG (Items)
  sku : "SKU",
  quantity : "Cantidad"

}

export default function __t(text) {
  return translates.hasOwnProperty(text) ? translates[text] : text
}