import { authTypes } from "../types/authTypes"

export const loginStart = () => ({
  type: authTypes.login_start,
  payload: null,
})

export const loginError = () => ({
  type: authTypes.login_error,
  payload: null,
})

export const loginSuccess = (user) => ({
  type: authTypes.login_success,
  payload: user,
})

export const logout = (user) => {
  window.localStorage.removeItem("finalDate")
  return {
    type: authTypes.logout,
    payload: null,
  }
}
