const getOptionsFilterSources = (permissions, values) => {
  let optionFilters = [];
  (values || []).map(({resourceId, name}) => {
    if (permissions[resourceId]) {
      optionFilters.push(name)
    }
    return false
  })
  return optionFilters
}

export default getOptionsFilterSources