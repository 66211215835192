import { axiosClientOLM } from "../config/axios";
import tokenAuth  from "../config/token";

export async function getAllScheduleSettingsDetails(token, json) {
	tokenAuth(token, "OLM");
	const url = `${process.env.REACT_APP_API_URL_SCHEDULE}/all`
	let result = await axiosClientOLM.post(url, json, {
		headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
		data: {},
	})
	return result;
}

export async function upsertScheduleSetting(token, json) {
  tokenAuth(token, "OLM")
  const url = process.env.REACT_APP_API_URL_SCHEDULE
	let result = await axiosClientOLM.put(url, json, {
    headers: {
    	Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {},
  })
  return result
}

export async function getScheduleSettingsDetails(token, id) {
	tokenAuth(token, "OLM");
	const url = `${process.env.REACT_APP_API_URL_SCHEDULE}/${id}`
	let result = await axiosClientOLM.get(url, {
		headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
		data: {},
	})
	return result;
}

export async function removeScheduleSetting(token, body) {
	tokenAuth(token, "OLM");
	const url = `${process.env.REACT_APP_API_URL_SCHEDULE}`
	let result = await axiosClientOLM.delete(url, {
		headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
		data: body,
	})
	return result;
}