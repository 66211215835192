import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import React from "react"
import useStyles from "./styles"
import Row from "./row"

import { CommonTableHead } from "@Components/CommonTable"
import CommonEmptyResult from "@Components/CommonTable/CommonEmptyResult"
import CommonLoader from "@Components/CommonTable/CommonTableLoader"
// import { useTranslation } from "react-i18next"

const TableReportDownload = ({
  columns,
  info,
  deleteReport,
  loadContent,
  setAvailable,
}) => {
  const classes = useStyles()
  // const { t } = useTranslation("global")
  return (
    <TableContainer className={classes.styledTableContainer}>
      <div className={classes.scrollerTable}>
        <Table stickyHeader size="medium" aria-label="a dense table">
          <CommonTableHead columns={columns} />

          {loadContent ? (
            <CommonLoader
              mobile={false}
              loaderContainerMobile={classes.loaderContainerMobile}
              tableBodyContainer={classes.tableBodyContainer}
              loaderContainer={classes.loaderContainer}
              length={columns.length}
            />
          ) : (
            <TableBody className={classes.tableBodyContainer}>
              {info.length > 0 ? (
                info.map((val) => {
                  return (
                    <React.Fragment key={val.creationDate}>
                      <Row
                        info={val}
                        deleteReport={deleteReport}
                        setAvailable={setAvailable}
                      />
                    </React.Fragment>
                  )
                })
              ) : (
                <CommonEmptyResult
                  mobile={false}
                  loaderContainerMobile={classes.loaderContainerMobile}
                  loaderContainer={classes.loaderContainer}
                  length={columns.length}
                />
              )}
            </TableBody>
          )}
        </Table>
      </div>
    </TableContainer>
  )
}

export default TableReportDownload
