import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  styledTableContainer: {
    maxHeight: "100%",
  },
  tableBodyContainer: {},
  loaderContainer: {
    minHeight: (props) =>
      props.moreSpace === true ? "calc(100vh - 245px)" : "calc(90vh - 235px)",
    display: "flex",
    // minWidth: "10vw",
    // maxWidth: "10vw",
    justifyContent: "center",
    alignItems: "center",
  },
  styledTableContainerMobile: {
    height: "auto",
    minHeight: "63vh",
  },
  loaderContainerMobile: {
    minHeight: "63vh",
    display: "flex",
    minWidth: "10vw",
    justifyContent: "center",
    alignItems: "center",
  },
  font400: {
    fontWeight: 400,
  },
  scrollerTable: {
    minHeight: (props) =>
      props.moreSpace === true ? "calc(100vh - 250px)" : "calc(90vh - 235px)",
    maxHeight: (props) =>
      props.moreSpace === true ? "calc(100vh - 250px)" : "calc(90vh - 235px)",
    ...theme.scrollbarStyles,
  },
  messageWaiting: {
    color: "#ff9800",
  },
  messagePackageNotReady: {
    color: "#c77700",
  },
  messageInTransit: {
    color: "#0b79d0",
  },
  messageReadyToPickup: {
    color: "#2196f3",
  },
  messageClose: {
    color: "#4caf50",
  },

  customMoreInfo: {
    backgroundColor: "#e5e5e5",
    minHeight: 400,
  },

  minimizeExtraInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  styleDiv: {
    padding: (props) =>
      props.generateData === true ? "10px" : "10px 10px 0px 10px",
  },
}))
