import {
  core,
  casaideas,
  sandbox,
  sodimac
} from "./clients"

const displayStatusByTypeHelper = ({
  type, // Can be "order" or "sg"
  status, 
  shippingType,
  reason,
  sgs
}) => {
  const clients = {
    core,
    casaideas,
    logytech: casaideas,
    sandbox,
    sodimac: sodimac({type, status, reason, sgs}),
  }
  let statusClient = clients?.[window.$website]?.[status]?.[shippingType]?.[type]
  let statusCore = clients?.core?.[status]?.[shippingType]?.[type]
  return statusClient || statusCore || status
}

export default displayStatusByTypeHelper;
