import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  mainContainer: {
    // maxWidth: 1200
  },
  labels: {
    fontWeight: 500,
    fontSize: 14,
    textTransform: "uppercase",
  },
  inputGroupActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "&:first-child": {
      marginTop: 10,
    },
    "&:last-child": {
      marginBottom: 10,
    },
  },
  cancelButton: {
    background: "#fff",
    color: "#FD0049",
    border: "solid 1px #FD0049",
    textTransform: "uppercase",
    "&:hover, &:focus": {
      backgroundColor: "#FAFAFA",
    },
  },
  createButton: {
    background: "#FD0049",
    color: "#fff",
    textTransform: "uppercase",
    border: "solid 1px #FD0049",
    "&:hover, &:focus": {
      backgroundColor: "#FD0000",
    },
    margin: "0 5px",
  },
  LoaderContainer: {
    minHeight: "80vh",
    display: "flex",
    minWidth: "10vw",
    justifyContent: "center",
    alignItems: "center",
  },
}))
