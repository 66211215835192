import React from "react"
import DashboardRoutes from "../../routes/DashboardRouter"
import ModalSessionQuestion from "@Components/ModalSessionQuestion"

const DashboardContainer = () => {
  return (
    <>
      <DashboardRoutes />
      <ModalSessionQuestion />
    </>
  )
}

export default DashboardContainer
