import React, { useState, useEffect, useContext, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { GlobalContext } from "@Context/context/GlobalState"
import { AuthContext } from "@Context/context/AuthContext"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { getRoutes } from "@Services/routeService"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormRoutes").render()

const EditRouteScreen = () => {
  const { user } = useContext(AuthContext)
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const { routeId } = useParams()
  const [load, setLoad] = useState(true)
  const [info, setInfo] = useState({})
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [rsc.RoFilterEditRoute],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (!permission[rsc.RoFilterEditRoute]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-route"),
      routes: [
        { name: t("logistics"), route: "/routes" },
        { name: t("edit-route"), route: `/routes/edit/${routeId}` },
      ],
      icon: <LocalShippingIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getRouteAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRouteAction = async () => {
    try {
      let response = await getRoutes(user.user.token, {
        skipRecords: 0,
        maxRecords: 1,
        filter: { id: routeId },
      })
      if (response?.data?.message?.totalRecordsMatched) {
        setInfo(response?.data?.message?.records[0] || {})
        setLoad(false)
      }
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component load={load} route={info} edit={true} />
    </Suspense>
  )
}

export default EditRouteScreen
