import React from "react"
import TableRow from "@material-ui/core/TableRow"
import TableFooter from "@material-ui/core/TableFooter"
import TablePagination from "@material-ui/core/TablePagination"
import Table from "@material-ui/core/Table"
import clsx from "clsx"
import useStyles from "@Components/theme/footer/style"
import FloatingMenuButton from "@Components/Shared/FloatingMenuButton"

function FooterTable({
  rowsPerPage,
  page,
  maxRecord,
  handleChangePage,
  handleChangeRowsPerPage,
  actionsOnItemsSelected,
  selectedNumber
}) {
  const classes = useStyles()

  return (
    <Table>
      <TableFooter>
        <TableRow
          className={clsx(
            classes.footerDefaultItemsPosition,
            !!selectedNumber && classes.footerDefaultItemsPositionWithMenuButton
          )}
        >
          {actionsOnItemsSelected && (
            <FloatingMenuButton
              actionsOnItemsSelected={actionsOnItemsSelected}
              selectedNumber={selectedNumber}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={maxRecord}
            onChangePage={(event, value) => handleChangePage(value)}
            onChangeRowsPerPage={(event, value) =>
              handleChangeRowsPerPage(event)
            }
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default FooterTable
