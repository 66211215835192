import React, { useEffect, useState, useContext, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { getScheduleSettingsDetails } from "@Services/scheduleService"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormSchedule").render()

const EditScheduleScreen = () => {
  const { user } = useContext(AuthContext)
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const { scheduleId } = useParams()
  const [schedule, setSchedule] = useState({})
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const {permission, checking} = useUserPermissions({resources: [
    rsc.SchCrudEdit
  ]})
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if(!permission[rsc.SchCrudEdit]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-schedule"),
      routes: [
        { name: t("logistics"), route: "/schedule" },
        { name: t("edit-schedule"), route: `/schedule/edit/${scheduleId}` },
      ],
      icon: <LocalShippingIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getScheduleAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getScheduleAction = async () => {
    try {
      let response = await getScheduleSettingsDetails(user.user.token, scheduleId)
      setSchedule(response.data.message)
      setLoad(false)
    } catch (error) {
      handleErrorSession({ error: true, errorCode: error.response.status, messageError: error?.response?.data?.message})
    }
  }

  return (
    <Suspense fallback>
      <Component schedule={schedule} edit={true} load={load} />
    </Suspense>
  )
}

export default EditScheduleScreen
