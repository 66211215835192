import defaultTranslate from "./es.js"
import adretailTranslate from "./es_adretail.js"
import casaideasTranslate from "./es_casaideas.js"
import sodimacTranslate from "./es_sodimac.js"

const getTranslate = (word, fileTranslated) => {
  if (fileTranslated.hasOwnProperty(word)) {
    return fileTranslated[word]
  } else {
    return defaultTranslate[word] || word
  }
}

const _translate = (word) => {
  let website = window.$website
  switch (website) {
    case "casaideas":
      return getTranslate(word, casaideasTranslate)
    case "sodimac":
      return getTranslate(word, sodimacTranslate)
    default:
      return getTranslate(word, adretailTranslate)
  }
}

export default _translate
