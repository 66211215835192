import { axiosClientOLM } from "../config/axios"
import tokenAuth from "../config/token"

async function getSources(token) {
  const json = {
    skipRecords: 0,
    maxRecords: 0,
    project: ["id", "alias"],
  }
  tokenAuth(token, "OLM")
  const url = process.env.REACT_APP_API_URL_SOURCES_OLM + "/all"

  let result = await axiosClientOLM.post(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

async function getRoutes(token, json) {
  tokenAuth(token, "OLM")
  if (json.hasOwnProperty("id")) {
    const url = process.env.REACT_APP_API_URL_ROUTES + `/${json.id}`
    let result = await axiosClientOLM.get(url, {
      headers: { "Content-Type": "application/json" },
      data: {},
    })
    return result
  } else {
    const url = process.env.REACT_APP_API_URL_ROUTES + `/all`
    let result = await axiosClientOLM.post(url, JSON.stringify(json), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    })
    return result
  }
}

async function getLocations0(token) {
  tokenAuth(token, "OLM")

  let result = await axiosClientOLM.get(
    process.env.REACT_APP_API_URL_LOCATION,
    {
      headers: { "Content-Type": "application/json" },
      data: {},
    }
  )
  return result
}

async function getLocations1(token, criteria1) {
  tokenAuth(token, "OLM")
  let result = await axiosClientOLM.get(
    process.env.REACT_APP_API_URL_LOCATION,
    {
      headers: { "Content-Type": "application/json" },
      params: { criteria0: criteria1 },
      data: {},
    }
  )
  return result
}

async function getLocations2(token, criteria1, criteria2) {
  tokenAuth(token, "OLM")
  const url =
    process.env.REACT_APP_API_URL_LOCATION +
    `?criteria0=${criteria1}&criteria1=${criteria2}`

  let result = await axiosClientOLM.get(url, {
    headers: { "Content-Type": "application/json" },
    data: {},
  })
  return result
}

export async function createOrUpdateRoute(token, json) {
  tokenAuth(token, "OLM")
  let result = await axiosClientOLM.put(
    process.env.REACT_APP_API_URL_ROUTES,
    json,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    }
  )
  return result
}

export { getSources, getRoutes, getLocations0, getLocations1, getLocations2 }
