import {useEffect, useState } from "react";

/*
 *Hook to manage the location of the popOver filters
 
 */
const useFiltersLocation = () => {

  //State to manage if one filter should be open
  const [openFilters, setopenFilters] = useState(false);
  //State to manage what filter should be open
  const [whoOpen, setWhoOpen] = useState(""); 
  //Flag to prevent error in first render , is in charge to render the filter if the component is mounted
  const [triger, setTriger] = useState(false); 
  //State to manage the filter location
  const [anchorEl, setAnchorEl] = useState(null); 

  //Prevent error with PopOver in First Render
  useEffect(() => {
    setTriger(true);
  }, []);

  //Set location of the popOver
  useEffect(() => {
    if (whoOpen !== "") {
      setopenFilters(true);
      setAnchorEl(document.getElementById(whoOpen));
    } else {
      setAnchorEl(null);
    }
  }, [whoOpen]);


  /*
  * Handle who open the Pop Over Filter and give his location
  * @param {object} event - Event element provide by onClick 
  * @return {void} Nothing
  
  */
  const openPopOverChip = (event) => {
    setopenFilters(true);
    setAnchorEl(event.currentTarget);
    setWhoOpen(event.currentTarget.id);
  };

  /*
  
  * Reset Pop Up Filter
  * @return {void} Nothing
  
  */
  const reset = () => {
    setWhoOpen("");
    setAnchorEl(null);
    setopenFilters(false);
  };

  return {triger, whoOpen, openFilters,anchorEl,setWhoOpen,setopenFilters, openPopOverChip, reset};
};

export default useFiltersLocation