import { openDB } from "idb"

const dbPromise = openDB("Files", 1, {
  upgrade(db) {
    // Create a store of objects
    const store = db.createObjectStore("Files", {
      // The 'id' property of the object will be the key.
      keyPath: "creationDate",
    })
    // Create an index on the 'date' property of the objects.
    store.createIndex("creationDate", "creationDate")
  },
})

export async function get(key) {
  return (await dbPromise).get("Files", key)
}
export async function set(key, val) {
  return (await dbPromise).put("Files", val, key)
}

export async function clear() {
  return (await dbPromise).clear("Files")
}
export async function keys() {
  return (await dbPromise).getAllKeys("Files")
}

export async function addElementDb(element) {
  return (await dbPromise).add("Files", element)
}
export async function updateElementInDb(element) {
  return (await dbPromise).put("Files", element)
}

export async function deleteElementInDb(key) {
  return (await dbPromise).delete("Files", key)
}

export async function getAllElementsInDb(key) {
  return (await dbPromise).getAll("Files", key)
}
