import React, { useEffect } from "react"
import { TreeItem, TreeView } from "@material-ui/lab"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import Typography from "@material-ui/core/Typography"
import useStyles from "./styles"
// import __t from "@Webcomponents/OmnixCustomData/i18n/es_ES"
import { useTranslation } from "react-i18next"
import _translate from "@Translates/index"

const TreeViewCustomFields = (props) => {
  const { t } = useTranslation("global")
  let { json, translateFuction, fieldNeedTranslation = false, notCountKeys } = props
  const classes = useStyles()
  const __t = (text) => {
    return translates.hasOwnProperty(text) ? translates[text] : t(_translate(text))
  }
  translateFuction = translateFuction ? translateFuction : __t
  useEffect(() => {}, [props])
  const translates = {
    // Sources
    sourceType: t("type-source"),
    addressStreet: t("street"),
    addressRegion: t("region"),
    addressCity: t("city"),
    addressComplement: t("address-complement"),

    // Casaideas custom orders
    sapId: "id_SAP",
    sistema: t("system"),
    paisId: "pais_id",
    localId: "local_id",
    startEstimatedDeliveryHour: "inicio_hora_entrega_estimada",
    endEstimatedDeliveryHour: "fin_hora_entrega_estimada",
    modoRegPedido: "modo_reg_pedido",
    modoRegVenta: "modo_reg_venta",
    regPedidoOrigen: "reg_pedido_origen",
    tipoDoc: "tipo_doc",

    // Adretail custom orders
    folio: t("ballot-number-folder"),

    // Default
    systemId: t("system-id"),
    name: t("name"),
    phone: t("phone"),
    email: t("email"),

    ///Custom coverage Table Object
    type: t("type"),
    data: t("information"),
    criteria0: "Criterio 0",
    criteria1: "Criterio 1",
    criteria2: "Criterio 2",
    criteria3: "Criterio 3",
    criteria4: "Criterio 4",

    ///For CSI table Order vs SG (Items)
    sku: "SKU",
    quantity: t("amount"),
    count: t("count"),
    city: t("city"),
    cityCode: t("city-code"),
    address: t("address"),
    coordinates: t("coordinates"),
    department: t("department"),
    region: t("region"),
    commune: t("commune"),
    barrio: t("neighborhood"),
  }

  const buildOuterLevel = (object, ruleName = "root") => {
    return (
      <>
        {object !== null &&
          object !== undefined &&
          Object.keys(object).map((val) => (
            <React.Fragment key={`${ruleName}-${val}`}>
              {typeof object[val] === "object" && object[val] !== null
                ? Object.entries(object[val]).length > 0 && (
                    <TreeItem
                      className={classes.itemTree}
                      nodeId={`${ruleName}-${val}`}
                      label={
                        <Typography variant="body2" noWrap>
                          <strong>{`${arrayLabel(val)}`}</strong>
                        </Typography>
                      }
                    >
                      {buildOuterLevel(object[val], `${ruleName}-${val}`)}
                    </TreeItem>
                  )
                : object[val] !== "" &&
                  object[val] !== null && (
                    <TreeItem
                      className={classes.itemTree}
                      nodeId={`${ruleName}-${val}`}
                      label={
                        <Typography variant="body2" noWrap>
                          <strong>{`${arrayLabel(val)}`}</strong>
                          {`: ${customLabel(object[val])}`}{" "}
                        </Typography>
                      }
                    />
                  )}
            </React.Fragment>
          ))}
      </>
    )
  }

  const customLabel = (label) => {
    if (typeof label === "boolean" && label !== null) {
      if (label) {
        return t("yes")
      } else {
        return t("no")
      }
    } else {
      return fieldNeedTranslation === false ? label : translateFuction(label)
    }
  }

  const arrayLabel = (label) => {
    if (isNumeric(label)) {
      return `__ ${notCountKeys ? parseInt(label) : (parseInt(label) + 1)}`
    } else {  
      return translateFuction(label)
    }
  }

  const isNumeric = (str) => {
    try {
      const d = parseInt(str)
      if (isNaN(d)) {
        return false
      }
    } catch (error) {
      return false
    }
    return true
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {typeof json === "string" ? json : buildOuterLevel(json)}
    </TreeView>
  )
}

export default TreeViewCustomFields
