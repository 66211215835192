const defaultTranslate = {
  all: "Todos",
  AWAITING_STORE_CONFIRMATION: "Esperando confirmación de la tienda",
  PENDING_PACKAGE: "Paquete pendiente",
  AWAITING_DELIVERY: "A la espera de la entrega",
  IN_TRANSIT: "En transito",
  READY_FOR_PICKUP: "Listo para recoger",
  DELIVERED_INCOMPLETE: "Entrega incompleta",
  INCOMPLETE_SHIPPING: "Envío incompleto",
  IN_RETURN_COURIER: "Devuelto por el mensajero",
  IN_RETURN_CUSTOMER: "Devuelto por el cliente",
  RETURN_IN_REVIEW: "Devolución en revisión",
  RETURNED: "Devuelto",
  REJECTED: "Rechazada",
  CANCELED: "Cancelado",
  CANCELED_DECREASE: "Cancelado (sin reposición de stock)",
  CANCELED_LOGISTIC: "Cancelado por logistica",
  CLOSED: "Cerrada",
  DELIVERED: "Entregada",
  RESERVED: "Reservado",
  PENDING_PREPARATION: "Pendientes por preparar",
  INCOMPLETE_DELIVERY: "Entrega incompleta",
  RESCHEDULED: "Reagendado",
  ShippingGroup: "shipping_group",
  Criteria0: "Criterio 0",
  Criteria1: "Criterio 1",
  Criteria2: "Criterio 2",
  Criteria3: "Criterio 3",
  Criteria4: "Criterio 4",
  item_order: "Reporte de órdenes detallado",
  availability: "Disponibilidad",
  outputs_of_service_retry: "Servicios de reintento",
  output_of_shippingroup_retry: "ShippingGroups reintento",
  orders_in_reserve: "Órdenes en reserva",
  output_of_service_request: "Request a servicios",
  transactions: "Transacciones",
  orders: "Reporte de órdenes",
  order_operation: "Reporte de Operación",
  items: "Reporte de Items",
}

export default defaultTranslate