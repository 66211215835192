import { tableTypes } from '../types/tableTypes';

export const resetTable = () => ({
    type: tableTypes.table_reset,
    payload: null
});
export const setGenerateDataTable = (info) => ({
    type: tableTypes.generateData_set,
    payload: info
});

export const setTableState = (info) => ({
    type: tableTypes.table_set,
    payload: info
});