import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  mainContainer: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    // maxWidth: 1200
  },
  tittleSection: {
    fontWeight: 400,
  },
}))
