import React, { useContext } from "react"
import Container from "@material-ui/core/Container"
import Header from "../header/index"
import Sidebar from "../Sidebar/index"
import Footer from "../footer/index"
import { GlobalContext } from "@Context/context/GlobalState"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useStyles from "./styles"
import { useTheme } from "@material-ui/core"
import RejectSessionAlert from "@Components/RejectSessionAlert"
import UnexpectedErrorAlert from "@Components/UnexpectedErrorAlert"

const Layout = ({ children }) => {
  const theme = useTheme()
  const { errorSession, unexpectedErrorAlert } = useContext(GlobalContext)
  const classes = useStyles()
  const width = useMediaQuery(theme.breakpoints.only("xs"))

  if (unexpectedErrorAlert) return <UnexpectedErrorAlert />
  if (errorSession) return <RejectSessionAlert />
  return (
    <div className={classes.root}>
      <Header width={width} />
      <Sidebar width={width} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {children}
        </Container>
      </main>
      <Footer />
    </div>
  )
}

export default Layout
