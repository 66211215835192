import { useState, useContext, useEffect } from "react"
import { AuthContext } from "@Context/context/AuthContext"
import JsonEngine from "@Config/jsonEngine"

const useUserPermissions = ({ resources }) => {
  const initState = (resources || []).reduce(
    (prev, current) => ({ ...prev, [current]: false }),
    {}
  )
  const { user } = useContext(AuthContext)
  const [permission, setPermission] = useState(initState)
  const [checking, setChecking] = useState(true)

  useEffect(() => {
    if ((resources || []).length === 0 || !user) return

    let isSubscribed = true
    ;(async () => {
      try {
        const params = {}
        const promesesPermissions = (resources || []).map((resource) =>
          hasPermission({ resource, params })
        )
        const resultPermissions = await Promise.all(promesesPermissions)

        if (isSubscribed) {
          setPermission((prevPermissions) => {
            const newPermissions = { ...prevPermissions }
            Object.keys(prevPermissions).forEach((key, index) => {
              newPermissions[key] = resultPermissions[index]
            })

            return newPermissions
          })
          setChecking(false)
        }
      } catch (error) {
        console.log("ERROR: ", error)
      }
    })()

    return () => {
      isSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const hasPermission = ({ resource, params }) => {
    return new Promise((resolve) => {
      if (!user) {
        resolve(false)
        return
      }
      const { groups, currentSources, mainGroup, permission } = user.user

      if (!permission || permission.length === 0) {
        resolve(true)
        return
      }

      const engine = JsonEngine.getInstance({
        rules: permission.flatMap(({ rule }) => rule),
      })
      const facts = {
        resource: resource,
        params: params,
        securityModel: { groups, currentSources, mainGroup, permission },
      }

      engine.run(facts).then((results) => {
        const allow = results.events.some(
          ({ type, params }) =>
            type === "Permission" && params && params.effect === "Allow"
        )
        resolve(allow)
      })
    })
  }

  return {
    permission,
    checking
  }
}

export default useUserPermissions
