import { GlobalContext } from "@Context/context/GlobalState"
import React, { useContext, useEffect } from "react"
import GetAppIcon from "@material-ui/icons/GetApp"
import DownloadReports from "@Components/DownloadReport"
import { useTranslation } from "react-i18next"
const DownloadReportScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("reports"),
      routes: [{ name: t("reports"), route: "/dowloadReports" }],
      icon: <GetAppIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <DownloadReports />
}

export default DownloadReportScreen
