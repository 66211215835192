import { createContext, useEffect, useReducer } from 'react';
import { authReducer } from '../reducers/authReducer';

export const AuthContext = createContext(null);
const initAuthReducer = () => {
    return JSON.parse(localStorage.getItem('user')) || {isLogged: false, user: null, expiration:  new Date().getTime()};
};

const AuthProvider = (props) => {
    const [user, setUser] = useReducer(authReducer, {}, initAuthReducer);
    
    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    return (
        <AuthContext.Provider value={{
            user,
            setUser 
        }}>
            {props.children}            
        </AuthContext.Provider>
    );

};

export default AuthProvider;