import { makeStyles } from "@material-ui/core/styles"
// import fondoLogin from "@Img/login/fondo.png"
// import fondoLoginNew from "@Img/login/fondo-person-new.png"
import fondoNeutral from "@Img/login/fondo-neutral.png"

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: theme.palette.background.default
    backgroundPosition: "bottom",
    backgroundSize: "100% 40%",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${fondoNeutral})`,
  },
  contLeft: {
    padding: 10,
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    // backgroundColor: "#FD0049",
    // backgroundPosition: "bottom center",
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundImage: `url(${fondoLoginNew})`,
  },
  contRight: {
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contMobile: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loginDesktopLogo: {
    maxWidth: "200px",
    margin: "2rem auto",
  },
  interactiveImg: {
    maxWidth: "60%",
  },
  descriptionText: {
    textAlign: "center",
    color: "gray",
    fontWeight: "500",
    margin: "2rem auto",
    fontSize: "1.2rem",
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  subDescriptionText: {
    textAlign: "center",
    marginLeft: 5,
    color: "gray",
    margin: "1rem auto",
    fontSize: "1.2rem",
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  constTopRight: {
    width: "100%",
    height: "10%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "10%",
  },
  constCenterRight: {
    width: "100%",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "15%",
  },
  bodyFormRight: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loginImgMobile: {
    width: "300px",
    margin: 15,
    paddingBottom: 30,
  },
  title: {
    color: "gray",
    fontSize: "1.2rem",
    fontWeight: 500,
    paddingBottom: 20,
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  formInputLabel: {
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  input: {
    width: "80%",
    flexGrow: 1,
    margin: "10px 5px",
    "& label.Mui-focused": {
      borderColor: "#FD0049",
      color: "#FD0049",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#FD0049",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FD0049",
      },
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "80%",
    flexGrow: 1,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    color: "rgba(0, 0, 0, 0.26)",
    fontStyle: "normal",
    letterSpacing: "0.46px",
    transition: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "#FD0049",
      boxShadow: "none",
    },
    "&:active": {
      color: "white",
      backgroundColor: "#FD0049",
      boxShadow: "none",
    },
    "&:focus": {
      color: "white",
      backgroundColor: "#FD0049",
      boxShadow: "none",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))

export default useStyles
