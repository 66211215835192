import axios from "axios"
import AxiosMethods from "./methods/AxiosMethods"

const axiosMethods = new AxiosMethods()

const axiosClient = axios.create({
  baseURL: axiosMethods.buildUrl("OSM"),
})

export const axiosClientOOM = axios.create({
  baseURL: axiosMethods.buildUrl("OOM"),
})

export const axiosClientOIM = axios.create({
  baseURL: axiosMethods.buildUrl("OIM"),
})

export const axiosClientOLM = axios.create({
  baseURL: axiosMethods.buildUrl("OLM"),
})

export const axiosClientDOWNLOAD = axios.create({
  baseURL: axiosMethods.buildUrl("DOWNLOAD"),
})

export const axiosClientOSRM = axios.create({
  baseURL: axiosMethods.buildUrl("OSRM"),
})

export default axiosClient
