import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  main: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 100,
    top: 0,
    left: 0,
    backdropFilter: "blur(8px)",
  },
  content: {
    minWidth: 400,
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    background: "#FFF",
    padding: 30,
    borderRadius: 30,
    border: "solid 2px #FD0049",
  },
  subContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  subContentButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  img: {
    width: "100%",
    paddingBottom: 20,
  },
  textRedirect: {
    paddingBottom: 20,
    fontWeight: 500,
  },
  no: {
    background: "#fff",
    color: "#FD0049",
    border: "solid 1px #FD0049",
    textTransform: "uppercase",
    "&:hover, &:focus": {
      backgroundColor: "#FAFAFA",
    },
    margin: "0 5px",
  },
  yes: {
    background: "#FD0049",
    color: "#fff",
    textTransform: "uppercase",
    border: "solid 1px #FD0049",
    "&:hover, &:focus": {
      backgroundColor: "#FD0000",
    },
    margin: "0 5px",
  },
}))
