import React from "react"
import { useState } from "react"

export const UseSessionEffect = () => {
  const [session, setSession] = useState(true)

  const DateToValidate = parseFloat(window.localStorage.getItem("finalDate"))
  const initialDate = new Date()
  const differentHours = DateToValidate - initialDate.getTime()

  React.useEffect(() => {
    if (differentHours <= 0) {
      setSession(false)
    }
  }, [differentHours])

  return { session, differentHours }
}
