import {axiosClientOLM} from "../config/axios";
import tokenAuth  from "../config/token";

async function getCoverage(token, json) {
	tokenAuth(token, "OLM");
    const url =  process.env.REACT_APP_API_URL_COVERAGES+'/all?response=expanded'
	let result = await axiosClientOLM.post(url, json, {
		headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
		data: {}
	});
	return result;
}

async function createOrUpdateCoverage(token, json) {
	tokenAuth(token, "OLM");
	let url = `${process.env.REACT_APP_API_URL_COVERAGES}`
	let result = await axiosClientOLM.put(url, json, {
		headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
		data: {},
	})
	return result;
}

export { getCoverage, createOrUpdateCoverage };