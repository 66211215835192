import { axiosClientOLM } from "../config/axios"
import tokenAuth from "../config/token"

export async function getLoadProcessById(token, id) {
	tokenAuth(token, "OLM");
	const url = process.env.REACT_APP_API_URL_MASSIVE_LOAD+`/${id}`
	let result = await axiosClientOLM.get(url, {
		headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
		data: {}
	});
	return result;
}

export async function getAllLoadProcess(token, json) {
	tokenAuth(token, "OLM");
	const url =  `${process.env.REACT_APP_API_URL_MASSIVE_LOAD}/all`
	let result = await axiosClientOLM.post(url, json, {
		headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
		data: {},
	})
	return result;
}

export async function uploadMassiveLoadFile(token, json, type) {
	tokenAuth(token, "OLM");
	const url =  `${process.env.REACT_APP_API_URL_MASSIVE_LOAD}?type=${type}`
	let result = await axiosClientOLM.post(url, json, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
	})
	return result;
}