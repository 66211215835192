import { axiosClientOLM } from "../config/axios";
import tokenAuth from "../config/token";


async function getCalendar(token) {
	tokenAuth(token, "OLM");		
		let result = await axiosClientOLM.get(process.env.REACT_APP_API_URL_CALENDAR, {
			headers: { 'Content-Type': 'application/json'},
			data: {},
		})
		return result;
	}


async function putCalendar(token, json) {
	tokenAuth(token, "OLM");
	let result = await axiosClientOLM.put(process.env.REACT_APP_API_URL_CALENDAR, json, {
		headers: { 'Content-Type': 'application/json' },
		data: {},
	})
	return result;
}

export { getCalendar, putCalendar }