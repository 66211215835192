const resources = {
  OrListOrder: "or-list-order",
  OrFilterOrderId: "or-filter-order-id",
  OrFilterChannel: "or-filter-channel",
  OrFilterOrderStatus: "or-filter-order-status",
  OrFilterCreationDate: "or-filter-creation-date",
  OrFilterCustomFolio: "or-filter-custom-folio",
  OrFilterCustomerId: "or-filter-customer-id",
  OrFilterSalesChannelId: "or-filter-sales-channel-id",
  OrFilterCustomerEmail: "or-filter-customer-email",
  OrFilterShippingType: "or-filter-shipping-type",
  OrFilterIncrementalId: "or-filter-incremental-id",
  OrCrudCreateSimpleOrder: "or-crud-create-simple-order",
  OrCrudCreateTransferOrder: "or-crud-create-transfer-order",
  OrCrudCreatePosOrder: "or-crud-create-pos-order",
  OrCrudEditOrder: "or-crud-edit-order",
  OrActionsConfirmOrder: "or-actions-confirm-order",
  OrActionsCancelOrder: "or-actions-cancel-order",
  OrHideCreateOrderButton: "or-hide-create-order-button",
  SgListSg: "sg-list-sg",
  SgFilterSgId: "sg-filter-sg-id",
  SgFilterChannelId: "sg-filter-channel-id",
  SgFilterStatus: "sg-filter-status",
  SgFilterSource: "sg-filter-source",
  SgFilterSalesChannelId: "sg-filter-sales-channel-id",
  SgFilterIncrementalId: "sg-filter-incremental-id",
  SgActionReschedule: "sg-action-reschedule",
  SgCrudCreationOrderRt: "sg-crud-creation-order-rt",
  UaListSg: "ua-list-sg",
  SgSalesChannelId: "sg-sales-channel-id",
  SoFilterSourceId: "so-filter-source-id",
  SoFilterSourceName: "so-filter-source-name",
  SoFilterSourceStatus: "so-filter-source-status",
  SoCrudCreateSource: "so-crud-create-source",
  SoCrudEditSource: "so-crud-edit-source",
  SoCrudDeleteSource: "so-crud-delete-source",
  SoListSource: "so-list-source",
  SoListHandleMultipleSources: "so-list-handle-multiple-sources",
  ScFilterCapacityId: "sc-filter-capacity-id",
  ScFilterCapacityDate: "sc-filter-capacity-date",
  ScCrudEditSourceCapacity: "sc-crud-edit-source-capacity",
  CaListCalendar: "ca-list-calendar",
  CaCrudEditCalendar: "ca-crud-edit-calendar",
  RoListRouteList: "ro-list-route-list",
  RoFilterRouteId: "ro-filter-route-id",
  RoFilterSource: "ro-filter-source",
  RoFilterTarget: "ro-filter-target",
  RoFilterCreateRoute: "ro-filter-create-route",
  RoFilterEditRoute: "ro-filter-edit-route",
  CoListCoverage: "co-list-coverage",
  CoFilterCoverageId: "co-filter-coverage-id",
  CoFilterCourierId: "co-filter-courier-id",
  CoFilterRouteId: "co-filter-route-id",
  CoFilterShippingType: "co-filter-shipping-type",
  CoFilterStatus: "co-filter-status",
  CoFilterDeliveryType: "co-filter-delivery-type",
  CoCrudCreateCoverage: "co-crud-create-coverage",
  CoCrudEditCoverage: "co-crud-edit-coverage",
  CourListCouriers: "cour-list-couriers",
  CourFilterCourierId: "cour-filter-courier-id",
  CourFilterAlias: "cour-filter-alias",
  CourCrudCreateCourier: "cour-crud-create-courier",
  CourCrudEditCourier: "cour-crud-edit-courier",
  LoListCriteria1: "lo-list-criteria-1",
  LoListCriteria2: "lo-list-criteria-2",
  LoListCriteria3: "lo-list-criteria-3",
  LoListLocation: "lo-list-location",
  LoCrudCreateLocation: "lo-crud-create-location",
  LoCrudEditLocation: "lo-crud-edit-location",
  LoCrudDeleteLocation: "lo-crud-delete-location",
  ChListChannel: "ch-list-channel",
  ChFilterChannelId: "ch-filter-channel-id",
  ChCrudCreate: "ch-crud-create",
  ChCrudEdit: "ch-crud-edit",
  ChCrudDelete: "ch-crud-delete",
  StoFilterStockId: "sto-filter-stock-id",
  StoCrudCreate: "sto-crud-create",
  StoCrudEdit: "sto-crud-edit",
  StoCrudDelete: "sto-crud-delete",
  ItListItems: "it-list-items",
  ItFilterChannelId: "it-filter-channel-id",
  ItFilterSku: "it-filter-sku",
  ItFilterCategory: "it-filter-category",
  ItFilterItemType: "it-filter-item-type",
  ItFilterItemStatus: "it-filter-item-status",
  ItFilterStorePickup: "it-filter--store-pickup",
  ItFilterVv: "it-filter--vv",
  ItFilterRut: "it-filter-rut",
  ItFilterFamily: "it-filter-family",
  ItCrudCreateItem: "it-crud-create-item",
  ItCrudEditItem: "it-crud-edit-item",
  ItCrudDeleteItem: "it-crud-delete-item",
  ItActionSafetyStockChannelSource: "it-action-safety-stock-channel-source",
  CmCrudLoadRoutecoverage: "cm-crud-load-routecoverage",
  CmListLoadRouteCoverage: "cm-list-load-routecoverage",
  CmFilterCmId: "cm-filter-cm-id",
  ReportOperation: "report-operation",
  ReportOrder: "report-order",
  ReportOrderDetail: "report-order-detail",
  Disponibility: "disponibility",
  OrderReserve: "order-reserve",
  RequestMs: "request-ms",
  ReportItems: "report-items",
  ReportCapacity: "report-capacity",
  Transactions: "transactions",
  Setting: "setting",
  SettingView: "settingview",
  OrdersGroup: "orders-group",
  LogisticGroup: "logistic-group",
  InventoryGroup: "inventory-group",
  LoadGroup: "load-group",
  ReportGroup: "report-group",
  SettingGroup: "setting-group",
  SchCrudList: "sch-crud-list",
  SchCrudCreate: "sch-crud-create",
  SchCrudEdit: "sch-crud-edit",
  SchCrudDelete: "sch-crud-delete",
}
export default resources;