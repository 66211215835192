import React from 'react'
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state"
import { useTranslation } from "react-i18next"

const FloatingMenuButton = ({actionsOnItemsSelected, selectedNumber}) => {
  const { t } = useTranslation("global")

  return (
    selectedNumber ? <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <Button
            {...bindTrigger(popupState)}
            color="primary"
            variant="contained"
            size="small"
            endIcon={<ArrowForwardIcon />}
            style={{
              height: 30,
            }}
          >
            {`(${selectedNumber}) ${t("selected")}`}
          </Button>
          <Menu {...bindMenu(popupState)}>
            {(actionsOnItemsSelected || []).map((action, index) => (
              <MenuItem
                key={index}
                size="small"
                onClick={() => {
                  action.onClick();
                  popupState.close();
                }}
              >
                {action.label}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState> : null
  )
}

export default FloatingMenuButton