import React, { useContext, useEffect, useState } from "react"
import Chip from "@material-ui/core/Chip"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Collapse from "@material-ui/core/Collapse"
import { Autocomplete } from "@material-ui/lab"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import RefreshIcon from "@material-ui/icons/Refresh"
import useStyles from "@Components/CommonReport/MainPageStyle/styles"
import { TableContext } from "@Context/context/TableContext"
import useFiltersInformation from "@Hooks/useFiltersInformation"
import useFiltersLocation from "@Hooks/useFiltersLocation"
import { useTranslation } from "react-i18next"
import { GlobalContext } from "@Context/context/GlobalState"
/*
 * Build the main view of a Report with a Autocomplete for filters , 2 Buttons one for redirect and another to  generate a table with the information.
 * @param {React.Component} TableComponent - Table Component of current view.
 * @param {React.Component} ButtonRedirect - Button Component to redirect to another side in the aplication, can be empty <></> .
 * @param {React.Component} PopOverComponent - PopOver for filters.
 * @param {String} tableName - This is the name search in Table Context to reload current selection, not all the tables implements TableContext.
 * @param {Object} defaultValueFilters - Json Object with defaullt values of the filters, usually this object is exported in filtersInfo.js file
 * @param {String []} optionsFilter - Array with all the names of the filters options , this will be find in the same file as defaultValueFilters
 * @param {Bool} disableSelectedById - If this parameter is true when a option with the name "Ïd" this will be disable all others options.
 * @param {Bool} disableByCustomField - If this parameter is true when a option the button to generate the table will be disabled using customFieldValidation as condition.
 * @param {function} customFieldValidation - Function to validate if the generate table button will be disable or not
 */

const CommonReport = ({
  TableComponent,
  ButtonRedirect,
  PopOverComponent,
  tableName,
  defaultValueFilters,
  optionsFilter,
  disableSelectedById = false,
  disableByCustomField = false,
  customFieldValidation,
}) => {
  const { table } = useContext(TableContext)
  const tableFilters =
    table.name === tableName ? table.filters : defaultValueFilters
  const optionsSelected = table.name === tableName ? table.val : []
  const displayData = table.name === tableName ? table.generateData : false
  const { t } = useTranslation("global")
  const {
    triger,
    whoOpen,
    openFilters,
    anchorEl,
    setWhoOpen,
    setopenFilters,
    openPopOverChip,
    // reset,
  } = useFiltersLocation()
  const { isLoadingAnyTable } = useContext(GlobalContext)

  const {
    // disable,
    valuesGenerate,
    generateData,
    val,
    disableExceptId,
    setVal,
    setGenerateData,
    handleValue,
    handleDelete,
    displayInfoChip,
    ResetAll,
    // checkEmpty,
  } = useFiltersInformation(
    [...tableFilters],
    displayData,
    optionsSelected,
    false,
    disableSelectedById
  )
  
  const [reload, setReload] = useState(false)
  const [disableButton, setDisableButton] = useState(disableByCustomField)

  useEffect(() => {
    if (disableByCustomField) {
      if (customFieldValidation(valuesGenerate)) {
        setDisableButton(true)
      } else {
        setDisableButton(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesGenerate])

  const renderTag = () => {
    return val.map((option) => {
      return (
        <Chip
          id={option}
          key={option}
          label={displayInfoChip(option)}
          clickable={true}
          onClick={openPopOverChip}
          onDelete={() => handleDelete(option, setWhoOpen)}
          disabled={checkIdSelected(option)}
        />
      )
    })
  }

  const checkIdSelected = (who) => {
    if (who !== "Id") {
      return disableExceptId
    } else {
      return false
    }
  }

  const handleValueTextField = (newValue) => {
    if (newValue.length > 0) {
      setVal([...newValue])
      setWhoOpen(newValue[newValue.length - 1])
    } else {
      ResetAll([...defaultValueFilters])
      setWhoOpen("")
    }
  }

  const classes = useStyles({ generateData: !generateData })

  return (
    <div className={classes.styleDiv}>
      <ButtonRedirect />
      {triger && (
        <PopOverComponent
          openFilters={openFilters}
          setopenFilters={setopenFilters}
          anchorEl={anchorEl}
          who={whoOpen}
          handleValue={handleValue}
          values={valuesGenerate}
        />
      )}
      <div style={{ display: "flex", gap: "0.5%", flexWrap: "wrap" }}>
        <div style={{ flexBasis: "90%", flexGrow: 1 }}>
          <Autocomplete
            getOptionDisabled={checkIdSelected}
            fullWidth
            value={val}
            filterSelectedOptions
            onChange={(e, newValue) => handleValueTextField(newValue)}
            multiple
            id="tags-standard"
            options={optionsFilter}
            getOptionLabel={(option) => option}
            renderTags={renderTag}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={t("filter-by")}
                placeholder={t("add-a-field")}
                variant="outlined"
              />
            )}
          />
        </div>

        {generateData && (
          <div style={{ flexGrow: 1, display: "flex", alignItems: "stretch" }}>
            <ButtonGroup
              fullWidth
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button onClick={() => setReload(true)} disabled={disableButton || isLoadingAnyTable}>
                <RefreshIcon />
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
      <div className={classes.styleDivContent}>
        {generateData === false ? (
          <Button
            disabled={disableButton}
            onClick={() => setGenerateData(true)}
            color="primary"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            {t("generate")}
          </Button>
        ) : (
          <Collapse in={generateData}>
            <TableComponent
              options={val}
              reload={reload}
              setReload={setReload}
              jsonFilter={valuesGenerate}
            />
          </Collapse>
        )}
      </div>
    </div>
  )
}

export default CommonReport
