import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import DatePickerTextField from "../DatePickerTextField"
import { useGetChannelsOIM } from "@Hooks/requests"

import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import useStyles from "../styles"

function Filters({ filters, value, setValue, channels, setChannels }) {
  const { t } = useTranslation("global")
  const classes = useStyles()

  const AutocompleteChannels = () => {
    const { channelsIds, load } = useGetChannelsOIM()

    const onInputChange = function (e, value) {
      if (typeof value === "undefined" || value === null) {
        return
      }
      setChannels(value)
    }

    return (
      <Fragment>
        <Typography variant="h6">{t("channels")}</Typography>
        <Autocomplete
          value={channels || []}
          onChange={onInputChange}
          multiple
          filterSelectedOptions
          options={channelsIds}
          getOptionLabel={(option) => option.toString()}
          getOptionSelected={(option, value) => option === value}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("channels") + "*"}
              placeholder={t("channels") + "*"}
              className={classes.input}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {load ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Fragment>
    )
  }

  const switchRender = (type) => {
    switch (type) {
      case "range":
        return (
          <Fragment key={type}>
            <Typography variant="h6">{t("filter-by-date")}</Typography>
            <DatePickerTextField date={value} setDate={setValue} />
          </Fragment>
        )
      case "channel":
        return <AutocompleteChannels key={type} />
      default:
        return <h1>{type}</h1>
    }
  }

  return (
    <>
      {filters.map((val) => {
        return switchRender(val)
      })}
    </>
  )
}

export default Filters
