import React, { useState, useEffect, useContext, Suspense } from "react"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"
import {
  getItems,
  getItemsQuantity,
  getItemsQuantityDiscountSafety,
} from "@Services/itemService"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("DetailItem").render()

const Layout = () => {
  const { id, channel } = useParams()
  const [info, setInfo] = useState([])
  const [load, setLoad] = useState(true)
  const { user } = useContext(AuthContext)
  const { handleErrorSession } = useContext(GlobalContext)
  const { permission, checking } = useUserPermissions({
    resources: [rsc.ItListItems],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (!permission[rsc.ItListItems]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    loadItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const loadItems = async () => {
    setLoad(true)
    try {
      let jsonValue = {
        channel: channel, //Cambiaron la propiedad antes era channel
        skipRecords: 0,
        maxRecords: 10,
        filter: { sku: id },
      }
      let responseItem = await getItems(user.user.token, jsonValue)
      const responseItemQuantity = await handleQueryItemsQuantity([id], channel)
      const responseItemQuantityDiscounted =
        await handleQueryItemsQuantityDiscounted([id], channel)

      setInfo({
        ...responseItem.data.message.records[0],
        quantity: buildQuantityObject(
          responseItemQuantity[0],
          responseItemQuantityDiscounted[0]
        ),
        safety: responseItemQuantity[0].safety,
      })

      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const handleQueryItemsQuantity = async (filter, channel) => {
    try {
      const responseItemsQuatity = await getItemsQuantity(
        user.user.token,
        filter,
        channel
      )
      return responseItemsQuatity.data.message
    } catch (error) {
      return [{ quantity: "Sin asignar", safety: {} }]
    }
  }
  const handleQueryItemsQuantityDiscounted = async (filter, channel) => {
    try {
      //Group by Source
      const responseItemsQuatity = await getItemsQuantityDiscountSafety(
        user.user.token,
        filter,
        channel
      )
      return responseItemsQuatity.data.message
    } catch (error) {
      return [{ quantity: "Sin asignar" }]
    }
  }

  const buildQuantityObject = (quantityCalculated, quantityDiscounted) => {
    return {
      stockDiscounted: quantityCalculated.quantity,
      stockCompleted: quantityDiscounted.quantity,
    }
  }

  return (
    <Suspense fallback>
      <Component data={info} loading={load} channel={channel} />
    </Suspense>
  )
}

export default Layout
