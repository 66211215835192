import { tableTypes } from "../types/tableTypes";
// import { values as ordersVal } from "../../components/CreateReport/filtersInfo";

export const tableReducer = (state, action) => {
  switch (action.type) {
    case tableTypes.generateData_set:
      return {
        ...state,generateData:action.payload
      };


    case tableTypes.table_set:
        return {
            ...action.payload
        };

    case tableTypes.table_reset:
      return {
        name:'empty',
        val :[],
        filters: [],
        page: 0,
        rowsPerPage: 10,
        generateData:false

      };

    default:
      return state;
  }
};
