import { createMuiTheme } from "@material-ui/core/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"
import * as locales from "@material-ui/core/locale"
const currentLocation = () => {
  const current = window.localStorage.getItem("SIMO-lng")
  if (current === "es") {
    return locales["esES"]
  } else if (current === "en") {
    return locales["enUS"]
  } else {
    return locales["esES"]
  }
}
const theme = createMuiTheme(
  {
    typography: {
      fontFamily: "Roboto",
      body1: {
        textTransform: "capitalize"
      }
    },
    palette: {
      primary: {
        main: "#FD0049",
        dark: "#B10033",
      },
      secondary: {
        main: "#2196f3",
      },
      black: "#000000",
      white: "#FFFFFF",
      gray: "#FAFAFA",
      tonalOffset: 0.1,
      background: {
        default: "#FAFAFA",
      },
    },
    overrides: {
      MuiSwitch: {
        colorSecondary: {
          "&$checked": {
            color: "#FD0049",
          },
          "&$checked + $track": {
            backgroundColor: "#FD0049",
          },
        },
      },
      MuiTab: {
        root: {
          "&$selected": {
            backgroundColor: "white",
          },
        },
      },
      MuiTableRow: {
        root: {
          "&$selected": { backgroundColor: fade("#fd0049", 0.1) },
          "&$selected:hover": { backgroundColor: fade("#fd0049", 0.1) },
        },
      },
    },
    scrollbarStyles: {
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        width: 15,
        height: 15,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "lightgray",
      },
    },
  },
  currentLocation()
)

export default theme
