import { axiosClientOIM } from "@Config/axios"
import tokenAuth from "@Config/token"

export async function getItems(token, json) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_ITEMS + "/all"
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function getItemsQuantity(token, filter, channel = "ecommerce") {
  tokenAuth(token, "OIM")
  const url =
    process.env.REACT_APP_API_URL_ITEMS +
    `/all/channel/${channel}?sourceGroup=false&allProperties=true`
  const json = { filter: filter }
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function getItemsQuantityDiscountSafety(
  token,
  filter,
  channel = "ecommerce"
) {
  tokenAuth(token, "OIM")
  const url =
    process.env.REACT_APP_API_URL_ITEMS +
    `/all/channel/${channel}?sourceGroup=false&allProperties=false`
  const json = { filter: filter, ignoreSafety: true }
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function getItemsQuantityBySource(token, filter, channel) {
  tokenAuth(token, "OIM")
  const url =
    process.env.REACT_APP_API_URL_ITEMS +
    `/all/channel/${channel}?sourceGroup=true&allProperties=false`
  const json = { filter: filter }
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}
export async function getItemsQuantityBySourceDiscountSafety(
  token,
  filter,
  channel
) {
  tokenAuth(token, "OIM")
  const url =
    process.env.REACT_APP_API_URL_ITEMS +
    `/all/channel/${channel}?sourceGroup=true&allProperties=false`
  const json = { filter: filter, ignoreSafety: true }
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function getItemWithStock(token, json, channel) {
  tokenAuth(token, "OIM")
  const url =
    process.env.REACT_APP_API_URL_ITEMS +
    `/all/channel/${channel}?sourceGroup=false&allProperties=true`
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function getItemsQuantityID(token, id) {
  const filter = id
  tokenAuth(token, "OIM")
  const json = { filter: [filter] }
  const url =
    process.env.REACT_APP_API_URL_ITEMS +
    "/all/channel/ecommerce?allProperties=true"
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}
export async function updateItemsQuantity(token, json) {
  tokenAuth(token, "OIM")
  let result = await axiosClientOIM.put(
    process.env.REACT_APP_API_URL_ITEMS,
    json,
    {
      headers: { "Content-Type": "application/json" },
    }
  )
  return result
}

export async function setItemSafetyBySources(token, itemSku, json) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_ITEMS + `/${itemSku}/safety/source`
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function setSafetyByChannel(token, json, sku) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_ITEMS + `/${sku}/safety`
  let result = await axiosClientOIM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}
