/**
 * Types - Information about the client's
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 * @returns {object}
 */
const ClientTypes = {
  set_env: "[client] SET Env",
  set_website: "[client] SET Website",
}

export default ClientTypes
