import { axiosClientOOM } from "@Config/axios"
import tokenAuth from "@Config/token"

export async function getMedia(token, json) {
  tokenAuth(token, "OOM")
  const url = process.env.REACT_APP_API_URL_ORDER + "/media"
  let result = await axiosClientOOM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}
