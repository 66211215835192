import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "@Context/context/GlobalState"
import { AuthContext } from "@Context/context/AuthContext"
import SettingsIcon from "@material-ui/icons/Settings"
import { useTranslation } from "react-i18next"
import { getSequenceConfig } from "@Services/orderService"
import DetailIncrementalOmnixId from "@Components/DetailIncrementalOmnixId"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"

const ShowIncrementalOmnixIdScreen = () => {
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const [load, setLoad] = useState(true)
  const [incrementalObject, setIncrementalObject] = useState({})
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [rsc.SettingView],
  })

  useEffect(() => {
    if (!checking) {
      if (!permission[rsc.SettingView]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("incremental-omnix-id"),
      routes: [
        { name: t("settings"), route: "/settings" },
        {
          name: t("incremental-omnix-id"),
          route: "/settings/incremental_omnix_id",
        },
      ],
      icon: <SettingsIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getIncrementalOmnix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  const getIncrementalOmnix = async () => {
    try {
      let response = await getSequenceConfig(user.user.token)
      setIncrementalObject(response.data.message)
      setLoad(false)
    } catch (error) {
      if (error.response.status === 404) {
        return history.goBack()
        // return history.push("/settings/incremental_omnix_id/new")
      } else {
        return handleErrorSession({
          error: true,
          errorCode: error.response.status,
        })
      }
    }
  }

  return (
    <DetailIncrementalOmnixId load={load} incremental={incrementalObject} />
  )
}

export default ShowIncrementalOmnixIdScreen
