import CircularProgress from "@material-ui/core/CircularProgress"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import React from "react"

function CommonLoader({
  mobile,
  loaderContainerMobile,
  tableBodyContainer,
  loaderContainer,
  length,
}) {
  return (
    <>
      {mobile ? (
        <span className={loaderContainerMobile}>
          <CircularProgress />
        </span>
      ) : (
        <TableBody className={tableBodyContainer}>
          <TableRow style={{ backgroundColor: "transparent" }}>
            <TableCell colSpan={length}>
              <span className={loaderContainer}>
                <CircularProgress />
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </>
  )
}

export default CommonLoader
