import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { useTranslation } from "react-i18next"
function CommonEmptyResult({
  mobile,
  loaderContainerMobile,
  loaderContainer,
  length,
}) {
  const { t } = useTranslation("global")
  return (
    <>
      {mobile ? (
        <div className={loaderContainerMobile}>
          <Typography>{t("without-results")}</Typography>
        </div>
      ) : (
        <TableRow style={{ backgroundColor: "transparent" }}>
          <TableCell colSpan={length}>
            <div className={loaderContainer}>
              <Typography>{t("without-results")}</Typography>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default CommonEmptyResult
