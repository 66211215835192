import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import format from "date-fns/format"
import React, { useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"
import { useTheme } from "@material-ui/core/styles"
import { getAllLoadProcess } from "@Services/massiveLoadsService"
import { ExpandMore } from "@material-ui/icons"
import useStyles from "@Components/CommonTable/CommonTableStyle/styles"
import useTable from "@Hooks/useTable"
import { CommonTableHead } from "@Components/CommonTable"
import FooterTable from "@Components/CommonTable/CommonTableFooter"
import CommonLoader from "@Components/CommonTable/CommonTableLoader"
import CommonEmptyResult from "@Components/CommonTable/CommonEmptyResult"
import TreeViewCustomFields from "@Components/CustomTreeView"
import { useTranslation } from "react-i18next"
import Tooltip from "@material-ui/core/Tooltip"
// const jsonValue = { filter: {} };
const multiLevel = false

const MassiveLoadsTable = ({ jsonFilter, reload, setReload, options }) => {
  const { handleErrorSession, setUnexpectedErrorAlert, setIsLoadingAnyTable } =
    useContext(GlobalContext)
  const theme = useTheme()
  const classes = useStyles()
  const width = useMediaQuery(theme.breakpoints.only("xs"))
  const { user } = useContext(AuthContext)
  const [load, setLoad] = useState(true)
  //Reset Scroll in pageChange
  const divRef = useRef(null)
  const { t } = useTranslation("global")
  const {
    page,
    rowsPerPage,
    info,
    valuesGenerate,
    // optionsValues,
    // expandTable,
    maxRecord,
    setPage,
    setInfo,
    setValuesGenerate,
    setOptionsValues,
    // setExpandTable,
    setMaxRecord,
    // minimizeAll,
    // handleExtraInformation,
    getIndexByKey,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTable(0, 10, [...jsonFilter], [...options], multiLevel, divRef) // Page,rowsPerPage,Filters,

  //Columns
  const columns = [
    t("load-id"),
    t("type"),
    t("file-detail"),
    t("current-state-of-charge"),
  ]

  //Table Managment in parent component

  useEffect(() => {
    getDataLoadFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, valuesGenerate])

  useEffect(() => {
    reloadActions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  useEffect(() => {
    setIsLoadingAnyTable(load)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load])
  
  const reloadActions = async () => {
    if (reload) {
      setOptionsValues([...options])
      if (page === 0) {
        setValuesGenerate([...jsonFilter])
        setReload(false)
      } else {
        setValuesGenerate([...jsonFilter])
        setReload(false)
        setPage(0)
      }
    }
  }

  //End Table Managment in parent component

  //Exclusive of the component
  const getDataLoadFunction = async () => {
    let token = user.user.token
    const json = createJson()
    try {
      setLoad(true)
      const response = await getAllLoadProcess(token, json)
      setInfo(response.data.message.records)
      setMaxRecord(response.data.message.totalRecordsMatched)
      setLoad(false)
    } catch (error) {
      if (error.response.status === 404) {
        setInfo([])
        setMaxRecord(0)
        setLoad(false)
      } else {
        handleErrorSession({
          error: true,
          errorCode: error.response.status,
          messageError: error?.response?.data?.message,
        })
      }
    }
  }

  const createJson = () => {
    const ind = getIndexByKey("Id")
    let jsonValue = {
      skipRecords: page * rowsPerPage,
      maxRecords: rowsPerPage,
    }
    if (valuesGenerate[ind].value !== "") {
      jsonValue = { ...jsonValue, filter: { id: valuesGenerate[ind].value } }
    }
    return jsonValue
  }
  //End Exclusive of the component

  const convertType = (type) => {
    let types = {
      routes: t("Route"),
      coverages: t("Coverage"),
      couriers: t("Courier"),
    }
    return types.hasOwnProperty(type) ? types[type] : "N/A"
  }

  const convertStatus = (type) => {
    let types = {
      UPLOADING: t("Uploading-file"),
      UPLOADED: t("Uploaded-file"),
      PROCESSING: t("Processing-file"),
      PROCESSED: t("Processed-file"),
      FAILED: t("Failed-file"),
    }
    return types.hasOwnProperty(type) ? types[type] : "N/A"
  }

  try {
    return (
      <TableContainer className={classes.styledTableContainer}>
        {width === false ? (
          <div ref={divRef} className={classes.scrollerTable}>
            <Table stickyHeader size="medium" aria-label="a dense table">
              <CommonTableHead
                columns={columns}
                multiLevel={multiLevel}
              />
              {load ? (
                <CommonLoader
                  mobile={false}
                  loaderContainerMobile={classes.loaderContainerMobile}
                  tableBodyContainer={classes.tableBodyContainer}
                  loaderContainer={classes.loaderContainer}
                  length={columns.length}
                />
              ) : (
                <TableBody className={classes.tableBodyContainer}>
                  {info.length > 0 ? (
                    info.map(({ id, type, file, currentStatus }) => {
                      return (
                        <React.Fragment key={id}>
                          <TableRow size="small">
                            <TableCell align="left">{id}</TableCell>
                            <TableCell align="left">
                              {convertType(type)}
                            </TableCell>
                            <TableCell align="left">
                              <TreeViewCustomFields json={file} />
                            </TableCell>
                            {/* <TableCell align="left">{convertStatus(currentStatus?.name)}</TableCell> */}
                            <TableCell align="left">
                              <Tooltip
                                title={currentStatus?.message || ""}
                                placement="bottom"
                              >
                                <Typography>
                                  {convertStatus(currentStatus?.name)}
                                </Typography>
                              </Tooltip>
                              <Typography>
                                {currentStatus?.date &&
                                  format(
                                    Date.parse(currentStatus?.date),
                                    "yyyy-LL-dd, HH:mm"
                                  )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })
                  ) : (
                    <CommonEmptyResult
                      mobile={false}
                      loaderContainerMobile={classes.loaderContainerMobile}
                      loaderContainer={classes.loaderContainer}
                      length={columns.length}
                    />
                  )}
                </TableBody>
              )}
            </Table>
          </div>
        ) : (
          <div className={classes.styledTableContainerMobile}>
            {load ? (
              <CommonLoader
                mobile={width}
                loaderContainerMobile={classes.loaderContainerMobile}
                tableBodyContainer={classes.tableBodyContainer}
                loaderContainer={classes.loaderContainer}
                length={columns.length}
              />
            ) : info.length > 0 ? (
              info.map(({ id, type, file, currentStatus }) => {
                return (
                  <React.Fragment key={id}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>{t("load-id") + ": " + id}</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography>
                          {t("type") + ":" + convertType(type)}
                        </Typography>
                        <Typography>
                          {t("file-detail")}
                          <TreeViewCustomFields json={file} />
                        </Typography>
                        <Typography>
                          {" "}
                          {t("current-state-of-charge") +
                            " : " +
                            convertStatus(currentStatus.name) || ""}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                )
              })
            ) : (
              <CommonEmptyResult
                mobile={width}
                loaderContainerMobile={classes.loaderContainerMobile}
                loaderContainer={classes.loaderContainer}
                length={columns.length}
              />
            )}
          </div>
        )}

        <FooterTable
          rowsPerPage={rowsPerPage}
          page={page}
          maxRecord={maxRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    )
  } catch (error) {
    setUnexpectedErrorAlert({
      reason: { message: error.message || "Unknow error" },
    })
    return null
  }
}

export default MassiveLoadsTable
