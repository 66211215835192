const core = {
  all: {
    HD: {
      order: "Todos",
      sg: "Todos"
    },
    SP: {
      order: "Todos",
      sg: "Todos"
    },
    RT: {
      order: "Todos",
      sg: "Todos"
    },
    TR: {
      order: "Todos",
      sg: "Todos"
    },
    POS: {
      order: "Todos",
      sg: "Todos"
    },
  },
  // Only Order Status
  RESERVED: {
    HD: {
      order: "Reservada"
    },
    SP: {
      order: "Reservada"
    },
    RT: {
      order: "Reservada"
    },
    TR: {
      order: "Reservada"
    },
    POS: {
      order: "Reservada"
    }
  },
  PENDING_PREPARATION: {
    HD: {
      order: "Preparación pendiente"
    },
    SP: {
      order: "Preparación pendiente"
    },
    RT: {
      order: "Preparación pendiente",
    },
    TR: {
      order: "Preparación pendiente",
    }
  },
  // Orders and Sgs Status together
  IN_SOURCE_PREPARATION: {
    SP: {
      order: "Preparando retiro",
      sg: "Preparando retiro"
    },
  },
  IN_TRANSIT: {
    HD: {
      order: "En tránsito",
      sg: "En tránsito"
    },
    SP: {
      order: "En tránsito",
      sg: "En tránsito"
    },
    RT: {
      order: "Devolución en tránsito",
      sg: "Devolución en tránsito"
    },
    TR: {
      order: "En tránsito",
      sg: "En tránsito"
    },
  },
  CANCELED: {
    HD: {
      order: "Cancelada",
      sg: "Cancelada"
    },
    SP: {
      order: "Cancelada",
      sg: "Cancelada"
    },
    RT: {
      order: "Cancelada",
      sg: "Cancelada"
    },
    TR: {
      order: "Cancelada",
      sg: "Cancelada"
    }
  },
  CLOSED: {
    HD: {
      order: "Cerrada",
      sg: "Cerrada"
    },
    SP: {
      order: "Cerrada",
      sg: "Cerrada"
    },
    RT: {
      order: "Cerrada",
      sg: "Cerrada"
    },
    TR: {
      order: "Cerrada",
      sg: "Cerrada"
    },
    POS: {
      order: "Cerrada",
    }
  },
  DELIVERED: {
    HD: {
      order: "Entregada",
      sg: "Entregada"
    },
    SP: {
      order: "Entregada",
      sg: "Entregada"
    },
    RT: {
      order: "Devolución entregada",
      sg: "Entregado en local de origen"
    },
    TR: {
      order: "Entregada",
      sg: "Entregada"
    },
    POS: {
      order: "Entregada",
      sg: "Entregada"
    }
  },
  // Only Sgs Status
  RESCHEDULED: {
    HD: {
      order: "Reagendada",
      sg: "Reagendada"
    },
    RT: {
      order: "Reagendada",
      sg: "Reagendada"
    },
    TR: {
      order: "Reagendada",
      sg: "Reagendada"
    }
  },
  AWAITING_STORE_CONFIRMATION: {
    HD: {
      sg: "Esperando confirmación de la tienda",
    },
    SP: {
      sg: "Esperando confirmación de la tienda",
    },
    RT: {
      sg: "Esperando confirmación de la tienda",
    },
    TR: {
      sg: "Esperando confirmación de la tienda",
    }
  },
  PENDING_PACKAGE: {
    HD: {
      sg: "Paquete pendiente",
    },
    SP: {
      sg: "Paquete pendiente",
    },
    RT: {
      sg: "Paquete pendiente",
    },
    TR: {
      sg: "Paquete pendiente",
    }
  },
  AWAITING_DELIVERY: {
    HD: {
      sg: "Listo para despachar"
    },
    SP: {
      sg: "Listo para despachar"
    },
    RT: {
      sg: "Esperando retiro"
    },
    TR: {
      sg: "Listo para despachar"
    }
  },
  READY_FOR_PICKUP: {
    SP: {
      order: "Listo para retiro",
      sg: "Listo para retiro"
    },
  },
  // Others
  RETURN_IN_REVIEW: {
    RT: {
      order: "Devolución en revisión",
      sg: "Devolución en revisión"
    }
  },
  INCOMPLETE_SHIPPING: {
    HD: {
      order: "Despacho incompleto",
      sg: "Despacho incompleto"
    },
    SP: {
      order: "Despacho incompleto",
      sg: "Despacho incompleto"
    },
    RT: {
      order: "Despacho incompleto",
      sg: "Despacho incompleto"
    },
    TR: {
      order: "Despacho incompleto",
      sg: "Despacho incompleto"
    }
  },
  DELIVERED_INCOMPLETE: {
    HD: {
      order: "Entrega incompleta",
      sg: "Entrega incompleta"
    }
  },
  DELIVERED_REJECT: {
    HD: {
      order: "Entrega rechazada",
      sg: "Entrega rechazada"
    }
  },
  IN_RETURN_COURIER: {
    HD: {
      sg: "Devuelto por el mensajero"
    },
    TR: {
      sg: "Devuelto por el mensajero"
    }
  },
  IN_RETURN_CUSTOMER: {
    HD: {
      order: "Devuelto por el cliente",
      sg: "Devuelto por el cliente"
    },
  },
  RETURNED: {
    HD: {
      order: "Devuelto",
      sg: "Devuelto"
    },
  },
  REJECTED: {
    HD: {
      order: "Rechazada",
      sg: "Rechazada"
    },
    SP: {
      order: "Rechazada",
      sg: "Rechazada"
    },
    RT: {
      order: "Rechazada",
      sg: "Rechazada"
    },
    TR: {
      order: "Rechazada",
      sg: "Rechazada"
    }
  },
  CANCELED_DECREASE: {
    HD: {
      order: "Cancelado sin modificar inventario",
      sg: "Cancelado sin modificar inventario"
    },
    TR: {
      order: "Cancelado sin modificar inventario",
      sg: "Cancelado sin modificar inventario"
    }
  },
  CANCELED_LOGISTIC: {
    HD: {
      order: "Cancelado por logística",
      sg: "Cancelado por logística"
    }
  },
}

export default core;