import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormCoverage").render()

const NewCoverageScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [rsc.CoCrudCreateCoverage],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (!permission[rsc.CoCrudCreateCoverage]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("create-coverage"),
      routes: [
        { name: t("logistics"), route: "/coverage" },
        { name: t("create-coverage"), route: "/coverage/new" },
      ],
      icon: <LocalShippingIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component load={false} />
    </Suspense>
  )
}

export default NewCoverageScreen
