import { createContext, useReducer } from 'react';
import { tableReducer } from '../reducers/tableReducer';
// import {values} from "../../components/CreateReport/filtersInfo";

export const TableContext = createContext(null);

const initTableReducer = () => {
    return {
        name : 'empty',
        val : [ ],
        filters : [],
        page : 0,
        rowsPerPage : 10,
        generateData: false
    }
}

const TableProvider = (props) => {
    const [table, setTable] = useReducer(tableReducer, {}, initTableReducer);
    


    return (
        <TableContext.Provider value={{
            table,
            setTable 
        }}>
            {props.children}            
        </TableContext.Provider>
    );

};

export default TableProvider;