import React, { useEffect, useState, useContext, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import { getStockById } from "@Services/stockService"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("DetailStock").render()

const ShowStockScreen = () => {
  const { stockId } = useParams()
  const { user } = useContext(AuthContext)
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const [stock, setStock] = useState({})
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const {permission, checking} = useUserPermissions({resources: [
    rsc.StoFilterStockId
  ]})
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if(!permission[rsc.StoFilterStockId]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])
  
  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("stock-detail"),
      routes: [
        { name: t("inventory"), route: "/stock" },
        { name: t("stock-detail"), route: `/stock/${stockId}` },
      ],
      icon: <AllInboxIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getStockAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStockAction = async () => {
    try {
      let response = await getStockById(user.user.token, stockId)
      setStock(response.data.message)
      setLoad(false)
    } catch (error) {
      handleErrorSession({ error: true, errorCode: error.response.status, messageError: error?.response?.data?.message})
    }
  }

  return (
    <Suspense fallback>
      <Component stock={stock} load={load} />
    </Suspense>
  )
}

export default ShowStockScreen
