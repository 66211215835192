import { axiosClientOIM } from "../config/axios"
import tokenAuth from "../config/token"

async function getChannels(token, json) {
  tokenAuth(token, "OIM")
  if (json.hasOwnProperty("id")) {
    const channel = json.id
    const url = process.env.REACT_APP_API_URL_CHANNEL + `/${channel}`
    let result = await axiosClientOIM.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    return result
  } else {
    let result = await axiosClientOIM.get(
      process.env.REACT_APP_API_URL_CHANNEL,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {},
      }
    )
    return result
  }
}

async function getChannelbyID(token, id) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_CHANNEL + `/${id}`
  let result = await axiosClientOIM.get(url, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
  })
  return result
}

async function updateChannel(token, json) {
  tokenAuth(token, "OIM")
  let result = await axiosClientOIM.put(
    process.env.REACT_APP_API_URL_CHANNEL,
    json,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
  return result
}

async function deleteChannel(token, id) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_CHANNEL + `/${id}`
  let result = await axiosClientOIM.delete(url, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
  })
  return result
}

async function gettingStockName(token, stocks, list) {
  tokenAuth(token, "OIM")
  let dataChannel = stocks.map(async (stock) => {
    try {
      const url = process.env.REACT_APP_API_URL_STOCK + `/${stock}`
      let response = await axiosClientOIM.get(url, {
        headers: { "Content-Type": "application/json" },
      })
      return { id: response.data.message.id, name: response.data.message.name }
    } catch (error) {
      return { id: "", name: "" }
    }
  })
  return Promise.all(dataChannel).then((result) => {
    return list.map((val) => {
      const indexFind = result.findIndex((stock) => {
        return stock.id === val.stock.id
      })
      return { ...val, stock: { ...result[indexFind] } }
    })
  })
}

export {
  getChannels,
  getChannelbyID,
  updateChannel,
  deleteChannel,
  gettingStockName,
}
