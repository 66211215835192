import { axiosClientOIM, axiosClientOLM } from "../config/axios"
import tokenAuth from "../config/token"

export async function getSources(token, json) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_SOURCES_OIM + `/all`
  let result = await axiosClientOIM.post(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

export async function createOrUpdateSource(token, json) {
  tokenAuth(token, "OIM")
  let result = await axiosClientOIM.put(
    process.env.REACT_APP_API_URL_SOURCES_OIM,
    json,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    }
  )
  return result
}

export async function getSourcesOLM(token, json) {
  tokenAuth(token, "OLM")
  const url = process.env.REACT_APP_API_URL_SOURCES_OLM + `/all`
  let result = await axiosClientOLM.post(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

export async function getAllSourcesOLM(token, items, rowsPerPage) {
  tokenAuth(token, "OLM")
  const ids = items.map(({ id }) => id)
  let params = {
    idsOrAliases: ids,
  }
  let result = await axiosClientOLM.post(
    process.env.REACT_APP_API_URL_SOURCES_OLM,
    params,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    }
  )
  return result
}

export async function createOrUpdateSourceOLM(token, json) {
  tokenAuth(token, "OLM")
  let result = await axiosClientOLM.put(
    process.env.REACT_APP_API_URL_SOURCES_OLM,
    json,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    }
  )
  return result
}

export async function deleteSourceOIM(token, idSource) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_SOURCES_OIM + `/${idSource}`
  let result = await axiosClientOIM.delete(
    url,
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
  return result
}

export async function deleteSourcesOLM(token, data) {
  tokenAuth(token, "OLM")
  const url = process.env.REACT_APP_API_URL_SOURCES_OLM
  let result = await axiosClientOLM.delete(url, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data,
  })
  return result
}
