import React, { useEffect, useContext, useState, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import { useParams } from "react-router-dom"
import { AuthContext } from "@Context/context/AuthContext"
import { getChannelbyID } from "@Services/channelService"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormChannel").render()

const EditChannelScreen = () => {
  const { t } = useTranslation("global")
  const { handleErrorSession, handleRoutingBreadcrumbs } =
    useContext(GlobalContext)
  const { channelId } = useParams()
  const { user } = useContext(AuthContext)
  const [load, setLoad] = useState(true)
  const [info, setInfo] = useState(null)
  const { permission, checking } = useUserPermissions({
    resources: [rsc.ChCrudEdit],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (!permission[rsc.ChCrudEdit]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-sales-channel"),
      routes: [
        { name: t("inventory"), route: "/channels" },
        { name: t("edit-sales-channel"), route: "/channels/edit/" + channelId },
      ],
      icon: <AllInboxIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getChannel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getChannel = async () => {
    setLoad(true)
    try {
      const response = await getChannelbyID(user.user.token, channelId)
      setInfo(response.data.message)
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component info={info} edit={true} load={load} />
    </Suspense>
  )
}

export default EditChannelScreen
