import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  styleDiv: {
    padding: (props) =>
      props.generateData === true ? "10px" : "10px 10px 0px 10px",
  },
  styledPaper: {
    backgroundColor: "white",
    minWidth: 320,
  },
  buttonGroupRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "10px 0 10px 0",
  },
  styleDivContent: {
    marginTop: "1%",
  },
}))
