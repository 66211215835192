import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapperMenuItem: {
    width: '10rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textStyle: {
    marginLeft: 5,
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  }
}));