import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import esImg from "@Img/ES.svg"
import enImg from "@Img/US.svg"
import useStyles from "./styles"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"

const SelectLanguage = () => {
  const classes = useStyles()
  const [currentLanguage, setCurrentLanguage] = useState("es")
  const { t, i18n } = useTranslation("global")

  useEffect(() => {
    const lngI18n = localStorage.getItem("i18nextLng")
    if (!lngI18n) {
      localStorage.setItem('i18nextLng', 'es')
      localStorage.setItem('SIMO-lng', 'es')
      setCurrentLanguage("es")
    } else {
      localStorage.setItem('SIMO-lng', lngI18n)
      setCurrentLanguage(lngI18n)
    }
    // i18n.changeLanguage(localStorage.getItem('SIMO-lng') || 'es')
  }, [])
  
  const handleChangeLanguage = (e) => {
    setCurrentLanguage(e.target.value)
    i18n.changeLanguage(e.target.value)
    localStorage.setItem("SIMO-lng", e.target.value)
    localStorage.setItem('i18nextLng', e.target.value)
    window.location.reload()
  }

  return (
    <Select value={currentLanguage} onChange={handleChangeLanguage}>
      <MenuItem value={"es"}>
        <span className={classes.wrapperMenuItem}>
          <img src={esImg} alt="spanish" width="25" />
          <Typography className={classes.textStyle}>{t("spanish")}</Typography>
        </span>
      </MenuItem>
      <MenuItem value={"en"}>
        <span className={classes.wrapperMenuItem}>
          <img src={enImg} alt="english" width="25" />
          <Typography className={classes.textStyle}>{t("english")}</Typography>
        </span>
      </MenuItem>
      {/* <MenuItem value={'pt'}>
        <span className={classes.wrapperMenuItem}>
          <img src={ptImg} alt='english' width="25" />
          <Typography className={classes.textStyle}>{t("portuguese")}</Typography>
        </span>
      </MenuItem> */}
    </Select>
  )
}

export default SelectLanguage
