const sodimac = ({type, status, reason, sgs}) => {
  const customStatus = () => {
    if (type === "order") {
      if (status === "PENDING_PREPARATION" && Array.isArray(sgs) && !!sgs.length) {
        let pending = {
          AWAITING_STORE_CONFIRMATION: "Creada",
          AWAITING_DELIVERY: "Pedido en curso"
        }
        let currentTagSG = sgs[0]?.currentStatus?.tag
        switch (currentTagSG) {
          case "AWAITING_STORE_CONFIRMATION":
            return pending[currentTagSG]
          case "AWAITING_DELIVERY":
            return pending[currentTagSG]
          case "CANCELED":
            const tagBeforeCanceled = sgs[0]?.status[sgs[0]?.status.length - 2]?.tag
            return pending[tagBeforeCanceled] || "N/A"
          default:
            return null
        }
      } else if (status === "CANCELED") {
        if (Array.isArray(sgs) && !!sgs.length) {
          return "Cancelada"
        }
        return reason || "Cancelada"
      } 
      return null
    }
  }

  return {
    // Without Sgs
    PENDING_PREPARATION: {
      HD: {
        order: customStatus(),
      },
    },
    // With Sgs
    AWAITING_STORE_CONFIRMATION: {
      HD: {
        sg: "Ingresado",
      },
    },
    AWAITING_DELIVERY: {
      HD: {
        sg: "Disponible despacho",
      }
    },
    IN_TRANSIT: {
      HD: {
        order: "En reparto",
        sg: "En reparto"
      },
    },
    CANCELED: {
      HD: {
        order: customStatus(),
        sg: "Cancelada"
      },
    },
    IN_RETURN_COURIER: {
      HD: {
        sg: "Estado Condución 'Re-ingreso'"
      },
    }
  }
}

export default sodimac;