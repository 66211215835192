import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import HomeIcon from "@material-ui/icons/Home"
import { useTranslation } from "react-i18next"
import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("HomeContent").render()

const HomeScreen = () => {
  const { t } = useTranslation("global")
  const {
    handleRoutingBreadcrumbs,
    // handleErrorSession
  } = useContext(GlobalContext)

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("home"),
      routes: [{ name: t("home"), route: "/dashboard" }],
      icon: <HomeIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Suspense fallback>
      <Component />
    </Suspense>
  )
}

export default HomeScreen