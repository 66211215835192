// import { grey } from '@material-ui/core/colors';
import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  styledTableContainer: {
    maxHeight: "auto",
  },
  tableBodyContainer: {},
  loaderContainer: {
    minHeight: (props) =>
      props.moreSpace === true ? "calc(100vh - 245px)" : "calc(70vh - 235px)",
    display: "flex",
    minWidth: "10vw",
    justifyContent: "center",
    alignItems: "center",
  },
  styledTableContainerMobile: {
    height: "auto",
    minHeight: "63vh",
  },
  loaderContainerMobile: {
    minHeight: "63vh",
    display: "flex",
    minWidth: "10vw",
    justifyContent: "center",
    alignItems: "center",
  },
  font400: {
    fontWeight: 400,
  },
  scrollerTable: {
    minHeight: (props) =>
      props.moreSpace === true ? "calc(100vh - 250px)" : "calc(90vh - 235px)",
    maxHeight: (props) =>
      props.moreSpace === true ? "calc(100vh - 250px)" : "calc(90vh - 235px)",
    ...theme.scrollbarStyles,
  },

  customMoreInfo: {
    backgroundColor: "#e5e5e5",
    minHeight: 400,
  },

  minimizeExtraInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor: "pointer",
  },

  dotAvailable: {
    height: 15,
    width: 15,
    backgroundColor: "#7CFC00",
    borderRadius: "50%",
    display: "inline-block",
  },
  dotEnable: {
    height: 15,
    width: 15,
    backgroundColor: "#ff1b1b",
    borderRadius: "50%",
    display: "inline-block",
  },
}))
