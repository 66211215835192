export const locationCasaideas = {
  /*Codigos Location */
  "056": "CHILE",
  "001": "TARAPACÁ",
  "002": "ANTOFAGASTA",
  "003": "ATACAMA",
  "004": "COQUIMBO",
  "005": "VALPARAÍSO",
  "006": "LIBERTADOR GENERAL BERNARDO O'HIGGINS",
  "007": "MAULE",
  "008": "BIOBÍO",
  "009": "LA ARAUCANÍA",
  "010": "LOS LAGOS",
  "011": "AYSÉN DEL GENERAL CARLOS IBÁÑEZ DEL CAMPO",
  "012": "MAGALLANES Y DE LA ANTÁRTICA CHILENA",
  "013": "METROPOLITANA DE SANTIAGO",
  "014": "LOS RÍOS",
  "015": "ARICA Y PARINACOTA",
  "016": "ÑUBLE",
  "056011": "IQUIQUE",
  "056014": "TAMARUGAL ",
  "056021": "ANTOFAGASTA",
  "056022": "EL LOA",
  "056023": "TOCOPILLA",
  "056031": "COPIAPO",
  "056032": "CHAÑARAL",
  "056033": "HUASCO",
  "056041": "ELQUI",
  "056042": "CHOAPA",
  "056043": "LIMARI",
  "056044": "COQUIMBO",
  "056051": "VALPARAISO",
  "056052": "ISLA DE PASCUA",
  "056053": "LOS ANDES",
  "056054": "PETORCA",
  "056055": "QUILLOTA",
  "056056": "SAN ANTONIO",
  "056057": "SAN FELIPE ACONCAGUA",
  "056058": "MARGA MARGA ",
  "056059": "LIMACHE",
  "056061": "CACHAPOAL",
  "056062": "CARDENAL CARO",
  "056063": "COLCHAGUA",
  "056064": "PICHILEMU",
  "056065": "PINTO",
  "056071": "TALCA",
  "056072": "CAUQUENES",
  "056073": "CURICO",
  "056074": "LINARES",
  "056081": "CONCEPCION",
  "056082": "ARAUCO",
  "056083": "BIOBIO",
  "056091": "CAUTIN",
  "056092": "MALLECO",
  "056093": "PUERTO VARAS",
  "056101": "LLANQUIHUE",
  "056102": "CHILOE",
  "056103": "OSORNO",
  "056104": "PALENA",
  "056105": "REQUINOA",
  "056111": "COYHAIQUE",
  "056112": "AYSEN",
  "056113": "CAPITAN PRAT",
  "056114": "GENERAL CARRERA",
  "056121": "MAGALLANES",
  "056122": "ANTARTICA CHILENA",
  "056123": "TIERRA DEL FUEGO",
  "056124": "ULTIMA ESPERANZA",
  "056131": "SANTIAGO",
  "056132": "CORDILLERA",
  "056133": "CHACABUCO",
  "056134": "MAIPO",
  "056135": "MELIPILLA",
  "056136": "TALAGANTE",
  "056141": "VALDIVIA ",
  "056142": "RANCO ",
  "056151": "ARICA ",
  "056152": "PARINACOTA ",
  "056161": "DIGUILLIN",
  "056162": "ITATA",
  "056163": "PUNILLA",
  "056164": "VILLARICA",
  "05601101": "IQUIQUE",
  "05601107": "ALTO HOSPICIO",
  "05601401": "POZO ALMONTE",
  "05601402": "CAMIÑA",
  "05601403": "COLCHANE",
  "05601404": "HUARA",
  "05601405": "PICA",
  "05602101": "ANTOFAGASTA",
  "05602102": "MEJILLONES",
  "05602103": "SIERRA GORDA",
  "05602104": "TALTAL",
  "05602201": "CALAMA",
  "05602202": "OLLAGUE",
  "05602203": "SAN PEDRO DE ATACAMA",
  "05602301": "TOCOPILLA",
  "05602302": "MARIA ELENA",
  "05603101": "COPIAPO",
  "05603102": "CALDERA",
  "05603103": "TIERRA AMARILLA",
  "05603201": "CHAÑARAL",
  "05603202": "DIEGO DE ALMAGRO",
  "05603301": "VALLENAR",
  "05603302": "ALTO DEL CARMEN",
  "05603303": "FREIRINA",
  "05603304": "HUASCO",
  "05604101": "LA SERENA",
  "05604102": "COQUIMBO",
  "05604103": "ANDACOLLO",
  "05604104": "LA HIGUERA",
  "05604105": "PAIGUANO",
  "05604106": "VICUÑA",
  "05604201": "ILLAPEL",
  "05604202": "CANELA",
  "05604203": "LOS VILOS",
  "05604204": "SALAMANCA",
  "05604301": "OVALLE",
  "05604302": "COMBARBALA",
  "05604303": "MONTE PATRIA",
  "05604304": "PUNITAQUI",
  "05604305": "RIO HURTADO",
  "05604401": "ENSENADA",
  "05604402": "LAS TACAS",
  "05604403": "PUERTO VELERO",
  "05604404": "TONGOY",
  "05605101": "VALPARAISO",
  "05605102": "CASABLANCA",
  "05605103": "CONCON",
  "05605104": "JUAN FERNANDEZ",
  "05605105": "PUCHUNCAVI",
  "05605107": "QUINTERO",
  "05605109": "VIÑA DEL MAR",
  "05605110": "CALETAS LAS DOCAS",
  "05605111": "LAGUNA VERDE",
  "05605112": "LAS TABLAS",
  "05605113": "PEÑUELAS",
  "05605114": "PUNTA BARRANCA",
  "05605115": "PUNTA CURAUMILLA",
  "05605201": "ISLA DE PASCUA",
  "05605301": "LOS ANDES",
  "05605302": "CALLE LARGA",
  "05605303": "RINCONADA",
  "05605304": "SAN ESTEBAN",
  "05605401": "LA LIGUA",
  "05605402": "CABILDO",
  "05605403": "PAPUDO",
  "05605404": "PETORCA",
  "05605405": "ZAPALLAR",
  "05605501": "QUILLOTA",
  "05605502": "CALERA",
  "05605503": "HIJUELAS",
  "05605504": "LA CRUZ",
  "05605506": "NOGALES",
  "05605601": "SAN ANTONIO",
  "05605602": "ALGARROBO",
  "05605603": "CARTAGENA",
  "05605604": "EL QUISCO",
  "05605605": "EL TABO",
  "05605606": "SANTO DOMINGO",
  "05605701": "SAN FELIPE",
  "05605702": "CATEMU",
  "05605703": "LLAILLAY",
  "05605704": "PANQUEHUE",
  "05605705": "PUTAENDO",
  "05605706": "SANTA MARIA",
  "05605801": "QUILPUE",
  "05605802": "LIMACHE",
  "05605803": "OLMUE",
  "05605804": "VILLA ALEMANA",
  "05605901": "PEÑA BLANCA",
  "05606101": "RANCAGUA",
  "05606102": "CODEGUA",
  "05606103": "COINCO",
  "05606104": "COLTAUCO",
  "05606105": "DOÑIHUE",
  "05606106": "GRANEROS",
  "05606107": "LAS CABRAS",
  "05606108": "MACHALI",
  "05606109": "MALLOA",
  "05606110": "MOSTAZAL",
  "05606111": "OLIVAR",
  "05606112": "PEUMO",
  "05606113": "PICHIDEGUA",
  "05606114": "QUINTA DE TILCOCO",
  "05606115": "RENGO",
  "05606116": "REQUINOA",
  "05606117": "SAN VICENTE",
  "05606201": "PICHILEMU",
  "05606202": "LA ESTRELLA",
  "05606203": "LITUECHE",
  "05606204": "MARCHIHUE",
  "05606205": "NAVIDAD",
  "05606206": "PAREDONES",
  "05606301": "SAN FERNANDO",
  "05606302": "CHEPICA",
  "05606303": "CHIMBARONGO",
  "05606304": "LOLOL",
  "05606305": "NANCAGUA",
  "05606306": "PALMILLA",
  "05606307": "PERALILLO",
  "05606308": "PLACILLA",
  "05606309": "PUMANQUE",
  "05606310": "SANTA CRUZ",
  "05606401": "MARCHIGUE",
  "05606501": "RECINTO",
  "05607101": "TALCA",
  "05607102": "CONSTITUCION",
  "05607103": "CUREPTO",
  "05607104": "EMPEDRADO",
  "05607105": "MAULE",
  "05607106": "PELARCO",
  "05607107": "PENCAHUE",
  "05607108": "RIO CLARO",
  "05607109": "SAN CLEMENTE",
  "05607110": "SAN RAFAEL",
  "05607201": "CAUQUENES",
  "05607202": "CHANCO",
  "05607203": "PELLUHUE",
  "05607301": "CURICO",
  "05607302": "HUALANE",
  "05607303": "LICANTEN",
  "05607304": "MOLINA",
  "05607305": "RAUCO",
  "05607306": "ROMERAL",
  "05607307": "SAGRADA FAMILIA",
  "05607308": "TENO",
  "05607309": "VICHUQUEN",
  "05607401": "LINARES",
  "05607402": "COLBUN",
  "05607403": "LONGAVI",
  "05607404": "PARRAL",
  "05607405": "RETIRO",
  "05607406": "SAN JAVIER",
  "05607407": "VILLA ALEGRE",
  "05607408": "YERBAS BUENAS",
  "05608101": "CONCEPCION",
  "05608102": "CORONEL",
  "05608103": "CHIGUAYANTE",
  "05608104": "FLORIDA",
  "05608105": "HUALQUI",
  "05608106": "LOTA",
  "05608107": "PENCO",
  "05608108": "SAN PEDRO DE LA PAZ",
  "05608109": "SANTA JUANA",
  "05608110": "TALCAHUANO",
  "05608111": "TOME",
  "05608112": "HUALPEN",
  "05608113": "AGUAS DE LA GLORIA",
  "05608114": "COPIULEMU",
  "05608115": "LA BOCA",
  "05608116": "RANGUELMO",
  "05608201": "LEBU",
  "05608202": "ARAUCO",
  "05608203": "CANETE",
  "05608204": "CONTULMO",
  "05608205": "CURANILAHUE",
  "05608206": "LOS ALAMOS",
  "05608207": "TIRUA",
  "05608301": "LOS ANGELES",
  "05608302": "ANTUCO",
  "05608303": "CABRERO",
  "05608304": "LAJA",
  "05608305": "MULCHEN",
  "05608306": "NACIMIENTO",
  "05608307": "NEGRETE",
  "05608308": "QUILACO",
  "05608309": "QUILLECO",
  "05608310": "SAN ROSENDO",
  "05608311": "SANTA BARBARA",
  "05608312": "TUCAPEL",
  "05608313": "YUMBEL",
  "05608314": "ALTO BIOBIO",
  "05609101": "TEMUCO",
  "05609102": "CARAHUE",
  "05609103": "CUNCO",
  "05609104": "CURARREHUE",
  "05609105": "FREIRE",
  "05609106": "GALVARINO",
  "05609107": "GORBEA",
  "05609108": "LAUTARO",
  "05609109": "LONCOCHE",
  "05609110": "MELIPEUCO",
  "05609111": "NUEVA IMPERIAL",
  "05609112": "PADRE LAS CASAS",
  "05609113": "PERQUENCO",
  "05609114": "PITRUFQUEN",
  "05609115": "PUCON",
  "05609116": "SAAVEDRA",
  "05609117": "TEODORO SCHMIDT",
  "05609118": "TOLTEN",
  "05609119": "VILCUN",
  "05609120": "VILLARRICA",
  "05609121": "CHOLCHOL",
  "05609201": "ANGOL",
  "05609202": "COLLIPULLI",
  "05609203": "CURACAUTIN",
  "05609204": "ERCILLA",
  "05609205": "LONQUIMAY",
  "05609206": "LOS SAUCES",
  "05609207": "LUMACO",
  "05609208": "PUREN",
  "05609209": "RENAICO",
  "05609210": "TRAIGUEN",
  "05609211": "VICTORIA",
  "05609301": "ENSENADA",
  "05610101": "PUERTO MONTT",
  "05610102": "CALBUCO",
  "05610103": "COCHAMO",
  "05610104": "FRESIA",
  "05610105": "FRUTILLAR",
  "05610106": "LOS MUERMOS",
  "05610107": "LLANQUIHUE",
  "05610108": "MAULLIN",
  "05610109": "PUERTO VARAS",
  "05610201": "CASTRO",
  "05610202": "ANCUD",
  "05610203": "CHONCHI",
  "05610204": "CURACO DE VELEZ",
  "05610205": "DALCAHUE",
  "05610206": "PUQUELDON",
  "05610207": "QUEILEN",
  "05610208": "QUELLON",
  "05610209": "QUEMCHI",
  "05610210": "QUINCHAO",
  "05610301": "OSORNO",
  "05610302": "PUERTO OCTAY",
  "05610303": "PURRANQUE",
  "05610304": "PUYEHUE",
  "05610305": "RIO NEGRO",
  "05610306": "SAN JUAN DE LA COSTA",
  "05610307": "SAN PABLO",
  "05610401": "CHAITEN",
  "05610402": "FUTALEUFU",
  "05610403": "HUALAIHUE",
  "05610404": "PALENA",
  "05610501": "REQUINOA",
  "05611101": "COYHAIQUE",
  "05611102": "LAGO VERDE",
  "05611201": "AYSEN",
  "05611202": "CISNES",
  "05611203": "GUAITECAS",
  "05611301": "COCHRANE",
  "05611302": "OHIGGINS",
  "05611303": "TORTEL",
  "05611401": "CHILE CHICO",
  "05611402": "RIO IBANEZ",
  "05612101": "PUNTA ARENAS",
  "05612102": "LAGUNA BLANCA",
  "05612103": "RIO VERDE",
  "05612104": "SAN GREGORIO",
  "05612201": "CABO DE HORNOS (EX-NAVARINO)",
  "05612202": "ANTARTICA",
  "05612301": "PORVENIR",
  "05612302": "PRIMAVERA",
  "05612303": "TIMAUKEL",
  "05612401": "NATALES",
  "05612402": "TORRES DEL PAINE",
  "05613101": "SANTIAGO",
  "05613102": "CERRILLOS",
  "05613103": "CERRO NAVIA",
  "05613104": "CONCHALI",
  "05613105": "EL BOSQUE",
  "05613106": "ESTACION CENTRAL",
  "05613107": "HUECHURABA",
  "05613108": "INDEPENDENCIA",
  "05613109": "LA CISTERNA",
  "05613110": "LA FLORIDA",
  "05613111": "LA GRANJA",
  "05613112": "LA PINTANA",
  "05613113": "LA REINA",
  "05613114": "LAS CONDES",
  "05613115": "LO BARNECHEA",
  "05613116": "LO ESPEJO",
  "05613117": "LO PRADO",
  "05613118": "MACUL",
  "05613119": "MAIPU",
  "05613120": "NUÑOA",
  "05613121": "PEDRO AGUIRRE CERDA",
  "05613122": "PEÑALOLEN",
  "05613123": "PROVIDENCIA",
  "05613124": "PUDAHUEL",
  "05613125": "QUILICURA",
  "05613126": "QUINTA NORMAL",
  "05613127": "RECOLETA",
  "05613128": "RENCA",
  "05613129": "SAN JOAQUIN",
  "05613130": "SAN MIGUEL",
  "05613131": "SAN RAMON",
  "05613132": "VITACURA",
  "05613133": "LO  ERRAZURIZ",
  "05613135": "EL CANELO",
  "05613136": "EL MELOCOTON",
  "05613137": "LA OBRA",
  "05613138": "LAS VERTIENTES",
  "05613139": "LO CHACON",
  "05613140": "LOYCA",
  "05613141": "MONTENEGRO",
  "05613142": "OCOA",
  "05613143": "PUEBLO HUNDIDO",
  "05613144": "QUIMCAHUE",
  "05613145": "VILLA ALHUE",
  "05613201": "PUENTE ALTO",
  "05613202": "PIRQUE",
  "05613203": "SAN JOSE DE MAIPO",
  "05613301": "COLINA",
  "05613302": "LAMPA ",
  "05613303": "TILTIL",
  "05613401": "SAN BERNARDO",
  "05613402": "BUIN",
  "05613403": "CALERA DE TANGO",
  "05613404": "PAINE",
  "05613501": "MELIPILLA",
  "05613502": "ALHUE",
  "05613503": "CURACAVI",
  "05613504": "MARIA PINTO",
  "05613505": "SAN PEDRO",
  "05613507": "CRUCE LAS ARAÑAS",
  "05613601": "TALAGANTE",
  "05613602": "EL MONTE",
  "05613603": "ISLA DE MAIPO",
  "05613604": "PADRE HURTADO",
  "05613605": "PEÑAFLOR",
  "05614101": "VALDIVIA",
  "05614102": "CORRAL",
  "05614103": "LANCO",
  "05614104": "LOS LAGOS",
  "05614105": "MAFIL",
  "05614106": "MARIQUINA",
  "05614107": "PAILLACO",
  "05614108": "PANGUIPULLI",
  "05614201": "LA UNION",
  "05614202": "FUTRONO",
  "05614203": "LAGO RANCO",
  "05614204": "RIO BUENO",
  "05615101": "ARICA",
  "05615102": "CAMARONES",
  "05615201": "PUTRE",
  "05615202": "GENERAL LAGOS",
  "05616101": "EL CARMEN",
  "05616102": "PEMUCO",
  "05616103": "PINTO",
  "05616104": "QUILLON",
  "05616105": "SAN IGNACIO",
  "05616106": "YUNGAY",
  "05616107": "CHILLAN",
  "05616108": "CHILLAN VIEJO",
  "05616109": "BULNES",
  "05616201": "NINHUE",
  "05616202": "PORTEZUELO",
  "05616203": "QUIRIHUE",
  "05616204": "RANQUIL",
  "05616205": "TREHUACO",
  "05616206": "COELEMU",
  "05616207": "COBQUECURA",
  "05616301": "COIHUECO",
  "05616302": "ÑIQUEN",
  "05616303": "SAN CARLOS",
  "05616304": "SAN FABIAN",
  "05616305": "SAN NICOLAS",
  "05616401": "LICANRAY",

  /* Fin Codigos Location */
}
