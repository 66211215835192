import { axiosClientOOM } from "../config/axios"
import tokenAuth from "../config/token"

export async function getOrders(token, json, orderState) {
  tokenAuth(token, "OOM")
  const url = process.env.REACT_APP_API_URL_ORDER + "/all/status/" + orderState
  let result = await axiosClientOOM.post(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

export async function gettingShippingGroup(token, orders) {
  tokenAuth(token, "OOM")
  let dataSgs = orders.map(async (order) => {
    if (order.currentStatus.tag !== "RESERVED") {
      try {
        const url =
          process.env.REACT_APP_API_URL_ORDER + `/${order.id}/shippinggroup`
        let response = await axiosClientOOM.get(url, {
          headers: { "Content-Type": "application/json" },
        })
        return response.data.message
      } catch (error) {
        if (error.response.status === 404) return []
      }
    } else {
      return []
    }
  })
  return Promise.all(dataSgs).then((result) => {
    let allSgs = []
    result.map((item) => (item || []).map((subItem) => allSgs.push(subItem)))
    return orders.map((order) => ({
      ...order,
      shippingGroup: allSgs.filter((sg) => sg.orderId === order.id),
      open: false,
    }))
  })
}

export async function getOrderDetails(token, id) {
  tokenAuth(token, "OOM")
  const url = process.env.REACT_APP_API_URL_ORDER + `/${id}`
  let result = await axiosClientOOM.get(url, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function getShippingGroupDetails(token, id, idShipping) {
  tokenAuth(token, "OOM")
  const url =
    process.env.REACT_APP_API_URL_ORDER + `/${id}/shippinggroup/${idShipping}`

  let result = await axiosClientOOM.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  return result
}

export async function getSchedule(token, json, channel) {
  tokenAuth(token, "OOM")
  const url = process.env.REACT_APP_API_URL_ORDER + `/schedule/${channel}`
  let result = await axiosClientOOM.post(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

export async function createOrder(token, json, params) {
  tokenAuth(token, "OOM")
  let result = await axiosClientOOM.put(
    process.env.REACT_APP_API_URL_ORDER,
    json,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
      params,
    }
  )
  return result
}

export async function updateOrder(token, json, id) {
  tokenAuth(token, "OOM")
  const url = process.env.REACT_APP_API_URL_ORDER + `/${id}`
  let result = await axiosClientOOM.post(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

export async function actionOrder(token, orderId, orderAction, json = {}) {
  tokenAuth(token, "OOM")
  let result = await axiosClientOOM.post(
    `${process.env.REACT_APP_API_URL_ORDER}/${orderId}/${orderAction}/`,
    json,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    }
  )
  return result
}

export async function getSequenceConfig(token) {
  tokenAuth(token, "OOM")
  const url = `${process.env.REACT_APP_API_URL_ORDER}/sequencer`
  let result = await axiosClientOOM.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  return result
}

export async function setSequenceConfig(token, json) {
  tokenAuth(token, "OOM")
  const url = `${process.env.REACT_APP_API_URL_ORDER}/sequencer`
  let result = await axiosClientOOM.put(url, json, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
  return result
}
