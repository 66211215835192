import { authTypes } from '../types/authTypes';

export const authReducer = (state, action) => {

    switch (action.type) {
        case authTypes.login_start:
            return {
                user: null,
                isLogged: false,
                expiration: null
            };

        case authTypes.login_error:
            return {
                user: null,
                isLogged: false,
                expiration: null
            };

        case authTypes.login_success:
            
            return {
                ...action.payload,
                isLogged: true, 
                expiration:  new Date().getTime()
            };

        case authTypes.logout:
            return {
                user: null,
                isLogged: false,
                expiration: null
            };

        default:
            return state;
    }

};