import React from "react"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import Typography from "@material-ui/core/Typography"

import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import useStyles from "./styles"

const CommonTableHead = ({
  columns,
  activeOrder,
  handleActive,
  filterDirection,
  expandTable,
  minimizeAll,
  multiLevel,
}) => {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow key="header" size="small">
        {columns.map((value, ind) =>
          value === "Minimizar Todas" ? (
            <React.Fragment key="last-cell">
              {/* {expandTable && (
                <TableCell
                  key="empty-space"
                  style={{ minWidth: 500 }}
                ></TableCell>
              )} */}
              <TableCell
                key={value}
                padding="default"
                align={"right"}
                onClick={minimizeAll}
              >
                <Typography
                  className={classes.minimizeExtraInfo}
                  noWrap
                  variant="subtitle2"
                  color="primary"
                >
                  <ExpandLessIcon></ExpandLessIcon>
                  {value}
                </Typography>
              </TableCell>
            </React.Fragment>
          ) : (
            <TableCell
              className={classes.headCell}
              key={value}
              padding="default"
              align={Math.ceil(columns.length / 2) > ind ? "left" : "left"}
            >
              {/* <TableSortLabel
              hideSortIcon={true}
              active={activeOrder === value + "-sort"}
              id={value + "-sort"}
              // key={value + "-sort"}
              direction={filterDirection ? "asc" : "desc"}
              onClick={handleActive}
            > */}
              <Typography noWrap variant="subtitle2">
                {value}
              </Typography>
              {/* </TableSortLabel> */}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

export default CommonTableHead
