/**
 * Returns a value within a array 
 * 
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 * @param {Array<string|number>} arr 
 * @param {string} value 
 * @returns {Array}
 */
export const getValueFromArray = (arr, value) =>
  arr ? arr.filter((key) => value === key) : []
