import React, { useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import PublishIcon from "@material-ui/icons/Publish"
import { TableMassiveLoads } from "@Components/TableMassiveLoads"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

const ListMassiveLoadsScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [rsc.CmListLoadRouteCoverage],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (!permission[rsc.CmListLoadRouteCoverage]) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("massive-loads"),
      routes: [{ name: t("massive-loads"), route: "/massive_loads" }],
      icon: <PublishIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TableMassiveLoads />
}

export default ListMassiveLoadsScreen
