import React, { useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import logoOmnix from "./../../assets/images/omnix-logo.svg"
import useStyles from "./styles"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"
import { logout } from "@Context/actions/AuthActions"
import { resetTable } from "@Context/actions/TableActions"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { TableContext } from "@Context/context/TableContext"
import { useTranslation } from "react-i18next"
import TreeViewCustomFields from "@Components/CustomTreeView"

const RejectSessionAlert = () => {
  const { t } = useTranslation("global")
  const classes = useStyles()
  const history = useHistory()
  const { setUser } = useContext(AuthContext)
  const { setTable } = useContext(TableContext)
  const { errorSession, errorSessionCode, messageError, handleErrorSession } =
    useContext(GlobalContext)

  useEffect(() => {
    setTable(resetTable())
    checkError()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkError = () => {
    if (errorSession && errorSessionCode === 401) {
      setTimeout(() => setUser(logout()), 5000)
    }
  }

  const errorString = errorSessionCode.toString()

  return (
    <div className={classes.main}>
      {errorSessionCode === 401 ? (
        <div className={classes.content}>
          <div className={classes.subContent}>
            <img src={logoOmnix} className={classes.img} alt="omnix" />
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              {t("your-session-has-expired-please-login-again-to-continue")}
            </Typography>
          </div>
          <div className={classes.subContent}>
            <Typography variant="subtitle1" className={classes.textRedirect}>
              {t("you-will-be-redirected-in-5-seconds")}
            </Typography>
            <CircularProgress color="primary" />
          </div>
        </div>
      ) : (
        <div className={classes.contentAltError}>
          <div className={classes.subContent}>
            <img src={logoOmnix} className={classes.img} alt="omnix" />
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              {t("sorry-we-could-not-find-the-page-you-are-looking-for")}
            </Typography>
          </div>
          <section className="error-container">
            <span>
              <span>{errorString[0]}</span>
            </span>
            <span>{errorString[1]}</span>
            <span>
              <span>{errorString[2]}</span>
            </span>
          </section>
          {messageError && (
            <TreeViewCustomFields
              json={{ reason: { message: messageError || "Unknown" } }}
            />
          )}
          <Button
            variant="contained"
            style={{
              color: "#FD0049",
              background: "#fff",
              border: "1px solid #FD0049",
              boxShadow: "none",
              marginRight: 15,
            }}
            size="small"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              handleErrorSession({
                error: false,
                errorCode: null,
                messageError: null,
              })
              history.push("/dashboard")
            }}
          >
            {t("go-to-the-home-page")}
          </Button>
        </div>
      )}
    </div>
  )
}

export default RejectSessionAlert
