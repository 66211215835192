import { axiosClientOOM } from "../config/axios"
import tokenAuth from "../config/token"

export async function gettingShippingGroupTable(token, json, state) {
  tokenAuth(token, "OOM")
  const url =
    process.env.REACT_APP_API_URL_ORDER +
    `/all/shippinggroup/all/status/${state}`
  let response = await axiosClientOOM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return response
}

export async function gettingShippingGroupByOrder(token, id) {
  tokenAuth(token, "OOM")
  const url = process.env.REACT_APP_API_URL_ORDER + `/${id}/shippinggroup`
  let response = await axiosClientOOM.get(url, {
    headers: { "Content-Type": "application/json" },
  })
  return response
}

export async function getSgById(token, id) {
  tokenAuth(token, "OOM")
  const url = process.env.REACT_APP_API_URL_ORDER + `/all/shippinggroup/${id}`
  let response = await axiosClientOOM.get(url, {
    headers: { "Content-Type": "application/json" },
  })
  return response
}

export async function gettingOrderbySG(token, orders) {
  tokenAuth(token, "OOM")
  let dataSgs = orders.map(async (order) => {
    try {
      const url = process.env.REACT_APP_API_URL_ORDER + `/${order}`
      let response = await axiosClientOOM.get(url, {
        headers: { "Content-Type": "application/json" },
      })
      const { id, custom, items } = response.data.message
      return { id, custom, items }
    } catch (error) {
      return { id: "", custom: {}, items: [] }
    }
  })

  return Promise.all(dataSgs).then((result) => {
    // let allSgs = []
    return result
    // result.map(item => item.map(subItem => allSgs.push(subItem)))
    // return orders.map(order => ({
    // 	...order, shippingGroup: allSgs.filter(sg => sg.orderId == order.id), open: false
    // })
  })
}

export async function gettingItemsOfOrder(token, orders) {
  tokenAuth(token, "OOM")
  let dataSgs = orders.map(async (order) => {
    try {
      const url = process.env.REACT_APP_API_URL_ORDER + `/${order}`
      let response = await axiosClientOOM.get(url, {
        headers: { "Content-Type": "application/json" },
      })
      const { id, items } = response.data.message
      const cleanItems = items.map(({ sku, name, quantity }) => ({
        sku,
        name,
        quantity,
      }))
      return { id, items: cleanItems }
    } catch (error) {
      return { id: "", items: [] }
    }
  })

  return Promise.all(dataSgs).then((result) => {
    return result
  })
}

export async function rescheduleSG(token, json, sg) {
  tokenAuth(token, "OOM")
  const url =
    process.env.REACT_APP_API_URL_ORDER + `/all/shippinggroup/${sg}/reschedule`
  let response = await axiosClientOOM.post(url, json, {
    headers: { "Content-Type": "application/json" },
  })
  return response
}
