import { Website } from "@Methods/"
const website = new Website()
const currentClient = website.getCurrentWebsite()
const currentEnv = website.getCurrentEnv()

const getWebsite = () => {
  return currentClient
}

export const getCurrentEnv = () => {
  return currentEnv
}

function firstLetterInMayus(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const nameComponent = () => {
  const web = firstLetterInMayus(getWebsite())
  const env = firstLetterInMayus(getCurrentEnv())
  const component = `${web}${env}`
  return component
}

export default getWebsite
