import Popover from "@material-ui/core/Popover"
import TextField from "@material-ui/core/TextField"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import React, { useEffect, useState } from "react"
import theme from "@Theme/themeMui"
import esLocale from "date-fns/locale/es"
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"

import {
  StaticDateRangePicker,
  DateRangeDelimiter,
  LocalizationProvider,
} from "@material-ui/pickers"
import { useTranslation } from "react-i18next"
function DatePickerTextField({ date, setDate }) {
  const [openFilters, setOpenFilters] = useState(false)
  const [anchorEl, setaAchorEl] = useState(null)
  const width = useMediaQuery(theme.breakpoints.only("xs"))
  const [showDate, setShowDate] = useState("")
  const { t } = useTranslation("global")
  useEffect(() => {
    setShowDate(getStringDate(date))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const openPopOverChip = (event) => {
    setOpenFilters(true)
    setaAchorEl(event.currentTarget)
  }

  const closeModal = () => {
    setOpenFilters(false)
    setaAchorEl(null)
  }

  const getStringDate = (value) => {
    if (value[0] === null && value[1] === null) {
      return ""
    }
    if (value[0] !== null && value[1] === null) {
      return buildFormatDate(value[0])
    }
    if (value[0] !== null && value[1] !== null) {
      return `${buildFormatDate(value[0])} - ${buildFormatDate(value[1])}`
    }
  }
  const buildFormatDate = (date) => {
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  return (
    <div>
      <Popover
        id="filter-date"
        open={openFilters}
        anchorEl={anchorEl}
        onClose={() => closeModal()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
          <StaticDateRangePicker
            calendars={width ? 1 : 2}
            displayStaticWrapperAs="desktop"
            value={date}
            onChange={(date) => setDate(date)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <DateRangeDelimiter> {t("to")} </DateRangeDelimiter>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </Popover>
      <TextField
        onClick={openPopOverChip}
        fullWidth
        label={t("date")}
        variant="outlined"
        value={showDate}
      ></TextField>
    </div>
  )
}

export default DatePickerTextField
