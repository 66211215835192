import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  minimizeExtraInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor:"pointer"
  },
  headCell:{
    transition:"all 0.3s ease-out",
    width:"auto"
  }
}));
