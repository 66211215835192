import Layout from "@Components/theme/layout"
import GlobalProvider from "@Context/context/GlobalState"
import { CssBaseline, MuiThemeProvider } from "@material-ui/core"
import React from "react"
import { Route, Redirect } from "react-router-dom"
import theme from "../../theme/themeMui"

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  return (
    <>
      {isAuthenticated ? (
        <GlobalProvider>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Layout>
              <Route
                {...rest}
                component={(props) => (
                  <>
                    <Component {...props} />
                  </>
                )}
              />
            </Layout>
          </MuiThemeProvider>
        </GlobalProvider>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  )
}

export default PrivateRoute
