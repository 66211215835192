import React, { createContext, useEffect, useReducer } from "react"
import { ClientReducer } from "@Context/reducers"
import { ClientReducerInit } from "@Context/inits"
import { ClientTypes } from "@Context/types"
import { Website } from "@Methods/"

export const ClientContext = createContext(null)

const ClientProvider = (props) => {
  const [client, setClient] = useReducer(ClientReducer, ClientReducerInit)

  // Accesibility from js files
  const website = new Website()
  window.$env = website.getCurrentEnv()
  window.$website = website.getCurrentWebsite()

  useEffect(() => {
    // Recommended: Accesibility from jsx files
    setClient({
      type: ClientTypes.set_env,
      payload: window.$env,
    })

    setClient({
      type: ClientTypes.set_website,
      payload: window.$website,
    })
  }, [])

  return (
    <ClientContext.Provider
      value={{
        client,
        setClient,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  )
}

export default ClientProvider
