import React, { useContext } from "react"
// import { useHistory } from "react-router-dom"

import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
// import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import TableBody from "@material-ui/core/TableBody"
// import CreateIcon from "@material-ui/icons/Create"
import useStyles from "./styles"
import { GlobalContext } from "@Context/context/GlobalState"
import { useTranslation } from "react-i18next"

const DetailIncrementalOmnixId = (props) => {
  const { load, incremental } = props
  const { setUnexpectedErrorAlert } = useContext(GlobalContext)
  // const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation("global")
  try {
    return (
      <>
        {load ? (
          <TableContainer component={Paper} className={classes.mainContainer}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <span className={classes.LoaderContainer}>
                      <CircularProgress />
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper} className={classes.mainContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Typography variant="body2" className={classes.labels}>
                          {t("id")}
                        </Typography>
                        <Typography variant="body2">
                          {incremental.id}
                        </Typography>
                      </div>
                      {/* <div className={classes.inputGroupActions}>
                        <Button
                          variant="contained"
                          size="small"
                          className={classes.createButton}
                          endIcon={<CreateIcon />}
                          // onClick={() => history.push(`/stock/edit/${stock.id}`)}
                        >
                          {t('edit')}
                        </Button>
                      </div> */}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.labels}>
                      {t("prefix")}
                    </Typography>
                    <Typography variant="body2">
                      {incremental.prefix}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.labels}>
                      {t("prefix-separator")}
                    </Typography>
                    <Typography variant="body2">
                      {incremental.prefixSeparator}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.labels}>
                      {t("suffix")}
                    </Typography>
                    <Typography variant="body2">
                      {incremental.suffix}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.labels}>
                      {t("suffix-separator")}
                    </Typography>
                    <Typography variant="body2">
                      {incremental.suffixSeparator}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.labels}>
                      {t("size")}
                    </Typography>
                    <Typography variant="body2">
                      {incremental.length}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.labels}>
                      {t("sequence-value")}
                    </Typography>
                    <Typography variant="body2">
                      {incremental.sequenceValue}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    )
  } catch (error) {
    setUnexpectedErrorAlert({
      reason: { message: error.message || "Unknow error" },
    })
    return null
  }
}

export default DetailIncrementalOmnixId
