import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import logoOmnix from "./../../assets/images/omnix-logo.svg"
import useStyles from "./styles"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { GlobalContext } from "@Context/context/GlobalState"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import TreeViewCustomFields from "@Components/CustomTreeView"
import { useTranslation } from "react-i18next"
const UnexpectedErrorAlert = () => {
  const classes = useStyles()
  const history = useHistory()
  const { unexpectedErrorAlert, setUnexpectedErrorAlert } =
    useContext(GlobalContext)
  const { t } = useTranslation("global")

  return (
    <div className={classes.main}>
      <div className={classes.contentAltError}>
        <div className={classes.subContent}>
          <img src={logoOmnix} className={classes.img} alt="omnix" />
          <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
            {t("sorry-an-unexpected-error-has-occurred")}
          </Typography>
        </div>
        <div className={classes.contentReasonError}>
          <TreeViewCustomFields json={unexpectedErrorAlert} expanded={true} />
        </div>
        <Button
          variant="contained"
          style={{
            color: "#FD0049",
            background: "#fff",
            border: "1px solid #FD0049",
            boxShadow: "none",
            marginRight: 15,
          }}
          size="small"
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            setUnexpectedErrorAlert(null)
            history.push("/dashboard")
          }}
        >
          {t("go-to-the-home-page")}
        </Button>
      </div>
    </div>
  )
}

export default UnexpectedErrorAlert
