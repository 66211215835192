import { axiosClientOIM } from "../config/axios"
import tokenAuth from "../config/token"

export async function getStockById(token, id) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_STOCK + `/${id}`
  let result = await axiosClientOIM.get(url, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function createStock(token, json) {
  tokenAuth(token, "OIM")
  let result = await axiosClientOIM.post(
    process.env.REACT_APP_API_URL_STOCK,
    json,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    }
  )
  return result
}

export async function updateStock(token, json, idStock) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_STOCK + `/${idStock}`

  let result = await axiosClientOIM.put(url, json, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })
  return result
}

export async function deleteStock(token, idStock) {
  tokenAuth(token, "OIM")
  const url = process.env.REACT_APP_API_URL_STOCK + `/${idStock}`

  let result = await axiosClientOIM.delete(
    url,
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {},
    }
  )
  return result
}
