import React, { useState, useEffect, useContext } from "react"
import { UseSessionEffect } from "@Hooks/useSessionEffect"
import { GlobalContext } from "@Context/context/GlobalState"
import logoOmnix from "./../../assets/images/omnix-logo.svg"
import useStyles from "./styles"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
const RejectSessionAlert = () => {
  const classes = useStyles()
  const { handleErrorSession } = useContext(GlobalContext)
  const { differentHours } = UseSessionEffect()
  const [warningSession, setWarningSession] = useState(false)

  useEffect(() => {
    differentHours && differentHours < 300000 && setWarningSession(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [differentHours])

  const continueSession = () => {
    const startDate = new Date()
    const newDate = startDate.setHours(startDate.getHours() + 3)
    window.localStorage.setItem("finalDate", newDate.toString())
    setWarningSession(false)
  }

  const rejectSession = () => {
    handleErrorSession({ error: true, errorCode: 401 })
  }
  const { t } = useTranslation("global")
  return (
    <>
      {warningSession && (
        <div className={classes.main}>
          <div className={classes.content}>
            <div className={classes.subContent}>
              <img src={logoOmnix} className={classes.img} alt="omnix" />
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                {t(
                  "your-session-is-about-to-expire-do-you-want-to-continue-at-SIMO"
                )}
              </Typography>
            </div>
            <div className={classes.subContentButtons}>
              <Button
                className={classes.no}
                variant="outlined"
                onClick={() => rejectSession()}
              >
                {t("no-exit-SIMO")}
              </Button>
              <Button
                className={classes.yes}
                variant="outlined"
                onClick={() => continueSession()}
              >
                {t("yes-continue")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RejectSessionAlert
