export const locationSodimac = {
  /*Codigos Location */
  "056": "CHILE",
  "005":"De Valparaíso",
  "006":"Del Libertador B. O'Higgins",
  "009":"De La Araucanía",
  "013":"Metropolitana de Santiago",
  "131":"Santiago",
  "132":"Cordillera",
  "133":"Chacabuco",
  "134":"Maipo",
  "135":"Melipilla",
  "136":"Talagante",
  "051":"Valparaíso",
  "052":"Isla de Pascua",
  "053":"Los Andes",
  "054":"Petorca",
  "055":"Quillota",
  "057":"San Felipe",
  "058":"Marga Marga",
  "061":"Cachapoal",
  "062":"Cardenal Caro",
  "063":"Colchagua",
  "091":"Cautín",
  "092":"Malleco",
  "13102":"Cerrillos",
  "13103":"Cerro Navia",
  "13104":"Conchalí",
  "13105":"El Bosque",
  "13106":"Estación Central",
  "13107":"Huechuraba",
  "13108":"Independencia",
  "13109":"La Cisterna",
  "13110":"La Florida",
  "13111":"La Granja",
  "13112":"La Pintana",
  "13113":"La Reina",
  "13114":"Las Condes",
  "13115":"Lo Barnechea",
  "13116":"Lo Espejo",
  "13117":"Lo Prado",
  "13118":"Macul",
  "13119":"Maipú",
  "13120":"Ñuñoa",
  "13121":"Pedro Aguirre Cerda",
  "13122":"Peñalolén",
  "13123":"Providencia",
  "13124":"Pudahuel",
  "13125":"Quilicura",
  "13126":"Quinta Normal",
  "13127":"Recoleta",
  "13128":"Renca",
  "13129":"San Joaquín",
  "13130":"San Miguel",
  "13131":"San Ramón",
  "13132":"Vitacura",
  "13201":"Puente Alto",
  "13202":"Pirque",
  "13203":"San José de Maipo",
  "13301":"Colina",
  "13302":"Lampa",
  "13303":"Tiltil",
  "13401":"San Bernardo",
  "13402":"Buin",
  "13403":"Calera de Tango",
  "13404":"Paine",
  "13501":"Melipilla",
  "13502":"Alhué",
  "13503":"Curacaví",
  "13504":"María Pinto",
  "13505":"San Pedro",
  "13601":"Talagante",
  "13602":"El Monte",
  "13603":"Isla de Maipo",
  "13604":"Padre Hurtado",
  "13605":"Peñaflor",
  "5101":"Valparaíso",
  "5102":"Casablanca",
  "5103":"Concón",
  "5104":"Juan Fernández",
  "5105":"Puchuncaví",
  "5107":"Quintero",
  "5109":"Viña del Mar",
  "5201":"Isla de Pascua",
  "5301":"Los Andes",
  "5302":"Calle Larga",
  "5303":"Rinconada",
  "5304":"San Esteban",
  "5401":"La Ligua",
  "5402":"Cabildo",
  "5403":"Papudo",
  "5404":"Petorca",
  "5405":"Zapallar",
  "5501":"Quillota",
  "5502":"Calera",
  "5503":"Hijuelas",
  "5504":"La Cruz",
  "5506":"Nogales",
  "5601":"San Antonio",
  "5602":"Algarrobo",
  "5603":"Cartagena",
  "5604":"El Quisco",
  "5605":"El Tabo",
  "5606":"Santo Domingo",
  "5701":"San Felipe",
  "5702":"Catemu",
  "5703":"Llaillay",
  "5704":"Panquehue",
  "5705":"Putaendo",
  "5706":"Santa María",
  "5801":"Quilpué",
  "5802":"Limache",
  "5803":"Olmué",
  "5804":"Villa Alemana",
  "6101":"Rancagua",
  "6102":"Codegua",
  "6103":"Coinco",
  "6104":"Coltauco",
  "6105":"Doñihue",
  "6106":"Graneros",
  "6107":"Las Cabras",
  "6108":"Machalí",
  "6109":"Malloa",
  "6110":"Mostazal",
  "6111":"Olivar",
  "6112":"Peumo",
  "6113":"Pichidegua",
  "6114":"Quinta de Tilcoco",
  "6115":"Rengo",
  "6116":"Requínoa",
  "6117":"San Vicente",
  "6201":"Pichilemu",
  "6202":"La Estrella",
  "6203":"Litueche",
  "6204":"Marchihue",
  "6205":"Navidad",
  "6206":"Paredones",
  "6301":"San Fernando",
  "6302":"Chépica",
  "6303":"Chimbarongo",
  "6304":"Lolol",
  "6305":"Nancagua",
  "6306":"Palmilla",
  "6307":"Peralillo",
  "6308":"Placilla",
  "6309":"Pumanque",
  "6310":"Santa Cruz",
  "9101":"Temuco",
  "9102":"Carahue",
  "9103":"Cunco",
  "9104":"Curarrehue",
  "9105":"Freire",
  "9106":"Galvarino",
  "9107":"Gorbea",
  "9108":"Lautaro",
  "9109":"Loncoche",
  "9110":"Melipeuco",
  "9111":"Nueva Imperial",
  "9112":"Padre Las Casas",
  "9113":"Perquenco",
  "9114":"Pitrufquén",
  "9115":"Pucón",
  "9116":"Saavedra",
  "9117":"Teodoro Schmidt",
  "9118":"Toltén",
  "9119":"Vilcún",
  "9120":"Villarrica",
  "9121":"Cholchol",
  "9201":"Angol",
  "9202":"Collipulli",
  "9203":"Curacautín",
  "9204":"Ercilla",
  "9205":"Lonquimay",
  "9206":"Los Sauces",
  "9207":"Lumaco",
  "9208":"Purén",
  "9209":"Renaico",
  "9210":"Traiguén",
  "9211":"Victoria",
  /* Fin Codigos Location */
}
