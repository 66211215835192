import { useEffect, useState } from "react"

const useTable = (
  pageProps,
  rowPerPageProps,
  jsonFilterProps,
  optionsProps,
  multiLevel,
  refScroll = null
) => {
  const [page, setPage] = useState(pageProps)
  const [rowsPerPage, setRowsPerPage] = useState(rowPerPageProps)
  const [info, setInfo] = useState([])
  const [valuesGenerate, setValuesGenerate] = useState([...jsonFilterProps])
  const [optionsValues, setOptionsValues] = useState([...optionsProps])
  const [expandTable, setExpandTable] = useState(false)
  const [maxRecord, setMaxRecord] = useState(0)

  //For Multilevel Table Only
  useEffect(() => {
    if (multiLevel) {
      if (checkIsOpen(info)) {
        setExpandTable(true)
      } else {
        setExpandTable(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  useEffect(() => {
    handleScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesGenerate, page, rowsPerPage])

  const minimizeAll = () => {
    const selected = info.map((values) => {
      return { ...values, open: false }
    })

    setInfo(selected)
  }
  const checkIsOpen = (array) => {
    const temp = array.map(({ open }) => {
      if (open === true) {
        return true
      } else {
        return false
      }
    })

    return temp.includes(true)
  }

  const handleExtraInformation = (idKey, keyProperty) => {
    const temp = info.findIndex((val) => val[keyProperty] === idKey)
    const newValue = { ...info[temp], open: !info[temp].open }
    const newArray = [...info]
    newArray[temp] = newValue
    setInfo([...newArray])
  }

  //End of MultiLevel Table Only

  const getIndexByKey = (key) => {
    const ind = valuesGenerate.findIndex((object) => object.name === key)
    return ind
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    handleChangePage(0)
  }

  const handleScroll = () => {
    if (refScroll) {
      refScroll.current.scrollTo(0, 0)
    }
  }

  return {
    page,
    rowsPerPage,
    info,
    valuesGenerate,
    optionsValues,
    expandTable,
    maxRecord,
    setPage,
    setInfo,
    setValuesGenerate,
    setOptionsValues,
    setExpandTable,
    setMaxRecord,
    minimizeAll,
    handleExtraInformation,
    getIndexByKey,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}

export default useTable
