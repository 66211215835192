// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case "HANDLE_DRAWER":
      return {
        ...state,
        openDrawer: !state.openDrawer,
      };
    case "HANDLE_ERROR_SESSION":
      return {
        ...state,
        errorSession: action.payload.error,
        errorSessionCode: action.payload.errorCode,
        messageError: action.payload.messageError,
      };
    case "HANDLE_ROUTING_BREADCRUMBS":
      return {
        ...state,
        routingBreadcrumbs: action.payload,
      };
    case "HANDLE_LOCATION":
      return {
        ...state,
        editLocation: action.payload,
      };
    case "HANDLE_UNEXPECTED_ERROR":
      return {
        ...state,
        unexpectedErrorAlert: action.payload,
      };
    case "HANDLE_IS_LOADING_ANY_TABLE":
      return {
        ...state,
        isLoadingAnyTable: action.payload,
      };
    default:
      return state;
  }
};
