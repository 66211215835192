import React, { useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import PersonIcon from "@material-ui/icons/Person"
import ProfileBody from "@Components/ProfileBody"
import { useTranslation } from "react-i18next"

const ProfileScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("user-profile"),
      routes: [{ name: t("user"), route: "/profile" }],
      icon: <PersonIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ProfileBody />
}

export default ProfileScreen
