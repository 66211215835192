import { makeStyles } from "@material-ui/core/styles"

const drawerWidth = 260

export default makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginRight:'auto',
    // justifyContent: 'space-between',
    paddingRight: 0, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0",
  },
  groupIconsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 0",
  },
  appBar: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    height: 80,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    textTransform: "capitalize",
    // "&::first-letter": {
    //   textTransform: "uppercase",
    // },
  },
  breadcrumb: {
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  breadcrumbActive: {
    opacity: 0.5,
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  hamburgerNavContainer: {
    display: "flex",
    flexDirection: "row",
  },
  iconBreadcrumbs: {
    marginRight: theme.spacing(0.5),
  },
  iconHolderDesktop : {
    display :'flex',
    justifyContent:'center',
    minWidth:'drawerWidth'
  },
  containerHeader : {
    display:'flex',
    alignItems:"center",
    padding:10,
    flexDirection: (props) =>
      props.width === true ? "row-reverse" : "row",
  },
  imageBrand : {
    maxWidth: drawerWidth,
    maxHeight: '30px'

  }
}));
