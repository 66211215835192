import React from "react"
import ReactDOM from "react-dom"

import "./assets/css/theme.css"
// import AppContainer from './containers/app';
import AppContainer from "./containers/app"
console.log("v0.03")

ReactDOM.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
  document.getElementById("root")
)
